export const Color = {
  first: '#FFD24C',
  second: '#92B4EC',
  opposite: '#FFFFFF',
  text: {
    first: '#1f1f1f',
    second: '#6d6d6d',
    light: '#B8B8B8',
    link: '#364C70',
  },
  success: '#198754',
  failure: '#ff3333',
}

export const PaddingNumber = {
  xxs: 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
  xxl: 32,
}

export const Padding = {
  xxs: `${PaddingNumber.xxs}px`,
  xs: `${PaddingNumber.xs}px`,
  sm: `${PaddingNumber.sm}px`,
  md: `${PaddingNumber.md}px`,
  lg: `${PaddingNumber.lg}px`,
  xl: `${PaddingNumber.xl}px`,
  xxl: `${PaddingNumber.xxl}px`,
}

export const Font = {
  xs: {
    size: '10px',
    sizeNumber: 10,
    weight: 400,
  },
  sm: {
    size: '13px',
    sizeNumber: 13,
    weight: 400,
  },
  smBold: {
    size: '13px',
    sizeNumber: 13,
    weight: 500,
  },
  mdLight: {
    size: '15px',
    sizeNumber: 15,
    weight: 400,
  },
  md: {
    size: '15px',
    sizeNumber: 15,
    weight: 500,
  },
  mdBold: {
    size: '15px',
    sizeNumber: 16,
    weight: 600,
  },
  lg: {
    size: '17px',
    sizeNumber: 17,
    weight: 600,
  },
  xl: {
    size: '20px',
    sizeNumber: 20,
    weight: 700,
  },
  xxl: {
    size: '24px',
    sizeNumber: 24,
    weight: 700,
  },
}

const shadowCardOppositeLgFirst = `${Color.opposite} 0 0 8px 4px`
const shadowCardOppositeLgSecond = `${Color.opposite} 0 0 12px 0`

export const Shadow = {
  text: {
    sm: '0 0 0.5px #000000',
  },
  card: {
    sm: '0.5px 0.5px 2px 0.5px #00000040',
    md: '2px 4px 8px #00000040',
    lg: 'rgba(50, 50, 93, 0.55) 0 50px 100px -20px, rgba(0, 0, 0, 0.6) 0 30px 60px -30px',
  },
  inCard: {
    lg: `rgba(10, 37, 64, 0.4) 0 -2px 6px 0 inset,
    ${shadowCardOppositeLgFirst} inset,
    ${shadowCardOppositeLgSecond} inset`,
  },
  cardOpposite: {
    lg: `${shadowCardOppositeLgFirst}, ${shadowCardOppositeLgSecond}`,
  },
}

export const Transition = {
  default: 'all 0.2s ease-in-out',
  fast: 'all 0.1s ease-in-out',
}

export const BorderRadius = {
  sm: '4px',
  md: '8px',
}

export const Filter = {
  disabled: 'opacity(0.6) contrast(0.5)',
}

export const Breakpoint = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
}

export const FontFamily = {
  code: 'Consolas, source-code-pro, Menlo, Monaco, "Ubuntu Mono", "source-code-pro", monospace',
}
