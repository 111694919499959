import { ElementName } from '../../../enums/Elements/ElementName'

const elements = [
  ElementName.theme,
  ElementName.screen,
  ElementName.mainImage,
  ElementName.restore,
  ElementName.close,
  ElementName.title,
  ElementName.features,
  ElementName.iapBlock,
  ElementName.purchaseButton,
  ElementName.privacy,
  ElementName.terms,
]

export default elements
