import { DefLinksConfigType, IDefLinkConfig, IState } from './types'
import { DefLinksConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/DefLinksConfigKey'
import {
  DefLinksConfigKeys as DefLinksConfigKeysType,
} from '../../../../models/enums/OtherConfigsKeys/keys/DefLinksConfigKeys'

export const getConfig = (
  state: IState,
): DefLinksConfigType => state.config.data

export const getConfigKey = (
  state: IState,
): DefLinksConfigKey.PERMANENT => state.currentConfigKeys.defLinksConfig

export const getDefLinkConfigs = (
  state: IState,
) => Object.entries(state.config.data)
  .reduce((acc, [configKey, configValue]) => {
    acc.push({
      key: configKey,
      data: configValue as IDefLinkConfig,
    })
    return acc
  }, [] as Array<{ key: string, data: IDefLinkConfig }>)

export const getDefLinkConfigByKey = (
  state: IState,
  key: DefLinksConfigKeysType | string,
): IDefLinkConfig | undefined => Object.entries(state.config.data)
  .find(([configKey]) => configKey === key)?.[1]

export const getCurrentDefLinkConfigData = (
  state: IState,
): IDefLinkConfig | undefined => Object.entries(state.config.data)
  .find(
    ([configKey]) => configKey === state.currentConfigKeys.defLinkConfig,
  )?.[1]

export const getCurrentDefLinkConfigKey = (
  state: IState,
): DefLinksConfigKeysType | string => state.currentConfigKeys.defLinkConfig
