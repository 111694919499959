import { Dispatch } from 'redux'
import { RootState } from '../../index'
import { ConfigName } from '../../../models/enums/ConfigName'
import {
  getConfig as getDefLinksConfig,
  getConfigKey as getDefLinksConfigKey,
  getCurrentDefLinkConfigData,
  getCurrentDefLinkConfigKey,
  getDefLinkConfigs,
} from './defLinksConfig/getters'
import {
  loadState as loadDefLinksConfigState,
} from './defLinksConfig'
import {
  getConfig as getDefLinksConditions,
  getConfigKey as getDefLinksConditionsKey,
  getCurrentDefLinkConditionData,
  getCurrentDefLinkConditionKey,
  getDefLinkConditions,
} from './defLinksConditions/getters'
import {
  loadState as loadDefLinkConditionsState,
} from './defLinksConditions'
import {
  getCurrentConfigKey as getCurrentAppConfigKey,
  getCurrentConfigData as getCurrentAppConfig,
  getConfigs as getAppConfigs,
} from './appConfig/getters'
import {
  loadState as loadAppState,
} from './appConfig'
import {
  getCurrentConfigKey as getCurrentMonetizationConfigKey,
  getCurrentHookKey,
  getCurrentSubscriptionKey,
  getCurrentConfigData as getCurrentMonetizationConfig,
  getCurrentHookConfig,
  getCurrentSubscriptionConfig,
  getConfigs as getMonetizationConfigs,
  getHookConfigs,
  getSubscriptionStatesConfigs,
} from './monetizationConfig/getters'
import {
  loadState as loadMonetizationConfigState,
} from './monetizationConfig'
import {
  getCurrentConfigKey as getCurrentScreenStyleConfigKey,
  getCurrentConfigData as getCurrentScreenStyleConfig,
  getConfigs as getScreenStyleConfigs,
} from './screenStyleConfig/getters'
import {
  loadState as loadScreenStyleConfigState,
} from './screenStyleConfig'

export const getCurrentConfigsKeys = (
  state: RootState,
) => ({
  [ConfigName.DEF_LINKS_CONFIG]: getDefLinksConfigKey(state.defLinksConfig),
  [ConfigName.DEF_LINK_CONFIG]: getCurrentDefLinkConfigKey(state.defLinksConfig),
  [ConfigName.DEF_LINKS_CONDITIONS]: getDefLinksConditionsKey(state.defLinksConditions),
  [ConfigName.DEF_LINK_CONDITION]: getCurrentDefLinkConditionKey(state.defLinksConditions),
  [ConfigName.APP_CONFIG]: getCurrentAppConfigKey(state.appConfig),
  [ConfigName.MONETIZATION_CONFIG]: getCurrentMonetizationConfigKey(state.monetizationConfig),
  [ConfigName.LIFE_CYCLE_CONFIG]: getCurrentHookKey(state.monetizationConfig),
  [ConfigName.SUBSCRIPTION_STATE_CONFIG]: getCurrentSubscriptionKey(state.monetizationConfig),
  [ConfigName.SCREEN_STYLE_CONFIG]: getCurrentScreenStyleConfigKey(state.screenStyleConfig),
})

export const getCurrentConfigs = (
  state: RootState,
) => ({
  [ConfigName.DEF_LINKS_CONFIG]: getDefLinksConfig(state.defLinksConfig),
  [ConfigName.DEF_LINK_CONFIG]: getCurrentDefLinkConfigData(state.defLinksConfig),
  [ConfigName.DEF_LINKS_CONDITIONS]: getDefLinksConditions(state.defLinksConditions),
  [ConfigName.DEF_LINKS_CONDITIONS]: getCurrentDefLinkConditionData(state.defLinksConditions),
  [ConfigName.APP_CONFIG]: getCurrentAppConfig(state.appConfig),
  [ConfigName.MONETIZATION_CONFIG]: getCurrentMonetizationConfig(state.monetizationConfig),
  [ConfigName.LIFE_CYCLE_CONFIG]: getCurrentHookConfig(state.monetizationConfig),
  [ConfigName.SUBSCRIPTION_STATE_CONFIG]: getCurrentSubscriptionConfig(state.monetizationConfig),
  [ConfigName.SCREEN_STYLE_CONFIG]: getCurrentScreenStyleConfig(state.screenStyleConfig),
})

export const getConfigs = (
  state: RootState,
) => ({
  [ConfigName.DEF_LINKS_CONFIG]: getDefLinksConfig(state.defLinksConfig),
  [ConfigName.DEF_LINK_CONFIG]: getDefLinkConfigs(state.defLinksConfig),
  [ConfigName.DEF_LINKS_CONDITIONS]: getDefLinksConditions(state.defLinksConditions),
  [ConfigName.DEF_LINK_CONDITION]: getDefLinkConditions(state.defLinksConditions),
  [ConfigName.APP_CONFIG]: getAppConfigs(state.appConfig),
  [ConfigName.MONETIZATION_CONFIG]: getMonetizationConfigs(state.monetizationConfig),
  [ConfigName.LIFE_CYCLE_CONFIG]: getHookConfigs(
    state.monetizationConfig,
    getCurrentMonetizationConfigKey(state.monetizationConfig),
  ),
  [ConfigName.SUBSCRIPTION_STATE_CONFIG]: getSubscriptionStatesConfigs(
    state.monetizationConfig,
    getCurrentHookKey(state.monetizationConfig),
    getCurrentMonetizationConfigKey(state.monetizationConfig),
  ),
  [ConfigName.SCREEN_STYLE_CONFIG]: getScreenStyleConfigs(state.screenStyleConfig),
})

export const loadStates = (dispatch: Dispatch) => {
  dispatch(loadDefLinksConfigState())
  dispatch(loadDefLinkConditionsState())
  dispatch(loadAppState())
  dispatch(loadMonetizationConfigState())
  dispatch(loadScreenStyleConfigState())
}
