import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './initialState'
import { ScreenStyleConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/types'
import {
  ICreateConfigActionPayload,
  IUpdateConfigActionPayload,
} from './types'
import {
  setCurrentConfigKeyReducer,
  createConfigReducer,
  updateConfigReducer,
  deleteConfigReducer,
  saveStateReducer,
  loadStateReducer,
} from './reducers'

export const screenStyleConfigSlice = createSlice({
  name: 'screenStyleConfig',
  initialState,
  reducers: {
    setCurrentConfigKey: (
      state,
      action: PayloadAction<ScreenStyleConfigKey | string>,
    ) => setCurrentConfigKeyReducer(state, action),
    createConfig: (
      state,
      action: PayloadAction<ICreateConfigActionPayload>,
    ) => createConfigReducer(state, action),
    updateConfig: (
      state,
      action: PayloadAction<IUpdateConfigActionPayload>,
    ) => updateConfigReducer(state, action),
    deleteConfig: (
      state,
      action: PayloadAction<ScreenStyleConfigKey | string>,
    ) => deleteConfigReducer(state, action),
    saveState: (
      state,
    ) => saveStateReducer(state),
    loadState: (
      state,
    ) => loadStateReducer(state),
  },
})

export const {
  setCurrentConfigKey,
  createConfig,
  updateConfig,
  deleteConfig,
  saveState,
  loadState,
} = screenStyleConfigSlice.actions

export default screenStyleConfigSlice.reducer
