import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PaywallEditor from '../views/PaywallEditor'
import { loadStates } from '../store/slices/configs'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    loadStates(dispatch)
  }, [dispatch])

  return (
    <div className="App">
      <main className="App-main">
        <PaywallEditor />
      </main>
    </div>
  )
}
export default App
