export enum ElementFullName {
  theme = 'Theme',
  screen = 'Screen',
  mainImage = 'Main Image',
  restore = 'Restore',
  close = 'Close',
  title = 'Title',
  features = 'Features',
  feature = 'Feature',
  feedbacks = 'Feedbacks',
  IAPBlock = 'In App Purchases Block',
  purchaseButton = 'Purchase Button',
  paymentInfo = 'Payment Info',
  privacy = 'Privacy',
  terms = 'Terms',
}
