import React, {
  ReactElement,
} from 'react'
import {
  IMenuItemProps,
} from './types'
import {
  StyledMenuItem,
} from './styled'

const MenuItem : React.FC<IMenuItemProps> = (
  {
    children,
    style,
    className,
    onClick,
  },
): ReactElement => (
  <StyledMenuItem onClick={onClick} style={style} className={className}>
    {children}
  </StyledMenuItem>
)

export default MenuItem
