type IphoneModel = 'x' | '11'

export const safeArea = {
  iphone(model: IphoneModel) {
    switch (model) {
      case 'x':
      case '11':
      default:
        return {
          top: '44px',
          bottom: '34px',
        }
    }
  },
}
