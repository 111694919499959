import { ScreenStyleConfigKeys } from '../../enums/ScreenStyleConfigKeys'
import severalOptionsWithFeatures from './SeveralOptionsWithFeatures'
import severalOptionsWithFeaturesElements from './SeveralOptionsWithFeatures/elements'
import oneOptionWithFeedbacks from './OneOptionWithFeedbacks'
import oneOptionWithFeedbacksElements from './OneOptionWithFeedbacks/elements'
import threeOptionsWithFeedbacks from './ThreeOptionsWithFeedbacks'
import threeOptionsWithFeedbacksElements from './ThreeOptionsWithFeedbacks/elements'

export const getScreenStyleConfig = (name: ScreenStyleConfigKeys) => {
  switch (name) {
    case ScreenStyleConfigKeys.OneOptionWithFeedbacks:
      return oneOptionWithFeedbacks
    case ScreenStyleConfigKeys.SeveralOptionsWithFeatures:
      return severalOptionsWithFeatures
    case ScreenStyleConfigKeys.ThreeOptionsWithFeedbacks:
      return threeOptionsWithFeedbacks
    default:
      console.log('[screenStyleConfig/getScreenStyleConfig] Error')
      return null
  }
}

export const getScreenStyleConfigElements = (name: ScreenStyleConfigKeys) => {
  switch (name) {
    case ScreenStyleConfigKeys.OneOptionWithFeedbacks:
      return oneOptionWithFeedbacksElements
    case ScreenStyleConfigKeys.SeveralOptionsWithFeatures:
      return severalOptionsWithFeaturesElements
    case ScreenStyleConfigKeys.ThreeOptionsWithFeedbacks:
      return threeOptionsWithFeedbacksElements
    default:
      console.log('[screenStyleConfig/getScreenStyleConfigElements] Error')
      return null
  }
}
