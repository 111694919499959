import React, {
  ReactElement, useMemo,
} from 'react'
import { IThemePlateProps } from './types'
import { ThemeColorKey, ThemeFontKey, ThemeKey } from '../../../../../../../models/enums/ConfigKeys/keys/ThemeKey'
import ConfigurationFieldsProvider
  from '../../../../../../../components/PaywallConfigurationFields/ConfigurationFieldsProvider'
import {
  StyledThemePlate,
  StyledThemeColumn,
  StyledThemeHeader,
  StyledThemeColumnWrapper,
} from './styled'
import { IFont } from '../../../../../../../models/types/paywallConfig/paywallElements'

const ThemePlate: React.FC<IThemePlateProps> = (
  {
    plateElements,
    config,
    onChange,
  },
): ReactElement => {
  const onFontChange = (configValue: IFont | undefined, fieldName: ThemeFontKey) => onChange({
    ...config,
    [ThemeKey.FONTS]: {
      ...config[ThemeKey.FONTS],
      [fieldName]: configValue,
    },
  })

  const onColorChange = (configValue: IFont | undefined, fieldName: ThemeColorKey) => onChange({
    ...config,
    [ThemeKey.COLORS]: {
      ...config[ThemeKey.COLORS],
      [fieldName]: configValue,
    },
  })

  const themeColors = useMemo(
    () => Object.entries(plateElements[ThemeKey.COLORS]),
    [plateElements],
  )

  const themeFonts = useMemo(
    () => Object.entries(plateElements[ThemeKey.FONTS]),
    [plateElements],
  )

  return (
    <StyledThemePlate>
      <StyledThemeColumnWrapper>
        <StyledThemeColumn>
          <StyledThemeHeader> Colors </StyledThemeHeader>
          {themeColors.map(([fieldName, fieldType]) => (
            <ConfigurationFieldsProvider
              key={fieldName}
              fieldType={fieldType.styleAttributeType}
              elementType={fieldType.elementType}
              fieldName={fieldName}
              configValue={config[ThemeKey.COLORS]?.[fieldName]}
              theme={config}
              onChange={(configValue) => onColorChange(configValue, fieldName as ThemeColorKey)}
              fieldNameFirst={false}
            />
          ))}
        </StyledThemeColumn>
      </StyledThemeColumnWrapper>
      <StyledThemeColumnWrapper>
        <StyledThemeColumn>
          <StyledThemeHeader> Fonts </StyledThemeHeader>
          {themeFonts.map(([fieldName, fieldType]) => (
            <ConfigurationFieldsProvider
              key={fieldName}
              fieldType={fieldType.styleAttributeType}
              elementType={fieldType.elementType}
              fieldName={fieldName}
              configValue={config[ThemeKey.FONTS]?.[fieldName]}
              theme={config}
              onChange={(configValue) => onFontChange(configValue, fieldName as ThemeFontKey)}
              fieldNameFirst={false}
            />
          ))}
        </StyledThemeColumn>
      </StyledThemeColumnWrapper>
    </StyledThemePlate>
  )
}

export default ThemePlate
