import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const StyledDefaultElementPlate = styled(Box)`
  
`

export const StyledDefaultElementColumn = styled(Box)`
  width: min-content;
`

export const StyledDefaultElementColumnWrapper = styled(Box)`
  width: 100%;
`
