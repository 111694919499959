import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './initialState'
import {
  ICreateConfigActionPayload,
  ICreateSubscriptionConfigActionPayload,
  ICreateHookConfigActionPayload,
  IUpdateConfigActionPayload,
  IUpdateHookConfigActionPayload,
  IUpdateSubscriptionConfigActionPayload,
  IDeleteHookConfigActionPayload,
  IDeleteSubscriptionConfigActionPayload,
} from './types'
import { MonetizationKey } from '../../../../models/enums/DefaultFirebaseRCKeys/types'
import {
  LifeCycleHook,
  SubscriptionState,
} from '../../../../models/enums/OtherConfigsKeys/types'
import {
  setCurrentConfigKeyReducer,
  setCurrentHookReducer,
  setCurrentSubscriptionStateReducer,
  createConfigReducer,
  createHookConfigReducer,
  createSubscriptionConfigReducer,
  updateConfigReducer,
  updateHookConfigReducer,
  updateSubscriptionConfigReducer,
  deleteConfigReducer,
  deleteHookConfigReducer,
  deleteSubscriptionConfigReducer,
  saveStateReducer,
  loadStateReducer,
} from './reducers'

export const monetizationConfigSlice = createSlice({
  name: 'monetizationConfig',
  initialState,
  reducers: {

    // Meta: setKey

    setCurrentConfigKey: (
      state,
      action: PayloadAction<MonetizationKey | string>,
    ) => setCurrentConfigKeyReducer(state, action.payload),
    setCurrentHook: (
      state,
      action: PayloadAction<LifeCycleHook | string>,
    ) => setCurrentHookReducer(state, action),
    setCurrentSubscriptionState: (
      state,
      action: PayloadAction<SubscriptionState>,
    ) => setCurrentSubscriptionStateReducer(state, action),

    // Meta: createConfig

    createConfig: (
      state,
      action: PayloadAction<ICreateConfigActionPayload>,
    ) => createConfigReducer(state, action.payload),
    createHookConfig: (
      state,
      action: PayloadAction<ICreateHookConfigActionPayload>,
    ) => createHookConfigReducer(state, action),
    createSubscriptionConfig: (
      state,
      action: PayloadAction<ICreateSubscriptionConfigActionPayload>,
    ) => createSubscriptionConfigReducer(state, action),

    // Meta: updateConfig

    updateConfig: (
      state,
      action: PayloadAction<IUpdateConfigActionPayload>,
    ) => updateConfigReducer(state, action),
    updateHookConfig: (
      state,
      action: PayloadAction<IUpdateHookConfigActionPayload>,
    ) => updateHookConfigReducer(state, action),
    updateSubscriptionConfig: (
      state,
      action: PayloadAction<IUpdateSubscriptionConfigActionPayload>,
    ) => updateSubscriptionConfigReducer(state, action),

    deleteConfig: (
      state,
      action: PayloadAction<MonetizationKey | string>,
      // TODO: add action.payload to other reducers
    ) => deleteConfigReducer(state, action.payload),
    deleteHookConfig: (
      state,
      action: PayloadAction<IDeleteHookConfigActionPayload>,
    ) => deleteHookConfigReducer(state, action),
    deleteSubscriptionConfig: (
      state,
      action: PayloadAction<IDeleteSubscriptionConfigActionPayload>,
    ) => deleteSubscriptionConfigReducer(state, action),

    saveState: (
      state,
    ) => saveStateReducer(state),
    loadState: (
      state,
    ) => loadStateReducer(state),
  },
})

export const {
  setCurrentConfigKey,
  setCurrentHook,
  setCurrentSubscriptionState,
  createConfig,
  createHookConfig,
  createSubscriptionConfig,
  updateConfig,
  updateHookConfig,
  updateSubscriptionConfig,
  deleteConfig,
  deleteHookConfig,
  deleteSubscriptionConfig,
  saveState,
  loadState,
} = monetizationConfigSlice.actions

export default monetizationConfigSlice.reducer
