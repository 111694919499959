export enum StyleAttributeType {
  element = 'element', // for elements inside elements
  array = 'array', // for arrays of elements
  unknown = 'unknown', // for new fields
  color = 'color',
  font = 'font',
  percentage = 'percentage',
  float = 'float',
  border = 'border',
  id = 'id',
  boolean = 'boolean',
  text = 'text',
}
