export enum ThemeKey {
  COLORS = 'colors',
  FONTS = 'fonts',
}

export enum ThemeColorKey {
  FIRST = 'first',
  SECOND = 'second',
  OPPOSITE = 'opposite',
  TEXT_H1 = 'textH1',
  TEXT_H2 = 'textH2',
  TEXT_H3 = 'textH3',
  TEXT_FIRST = 'textFirst',
  TEXT_SECOND = 'textSecond',
  TEXT_LINK = 'textLink',
}

export enum ThemeFontKey {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  LINK = 'link',
}
