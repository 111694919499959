import styled from '@emotion/styled/macro'
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import { IStyledProps } from '../../../models/types/helper/styledComponents'
import { ITooltipTheme } from './types'
import {
  Color, Font, Padding, Shadow,
} from '../../../theme'

export const StyledTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ),
)<IStyledProps<ITooltipTheme>>(
  () => ({
    opacity: 0.8,
    [`& .${tooltipClasses.tooltip}`]: {
      paddingLeft: Padding.md,
      paddingRight: Padding.md,
      paddingTop: Padding.xs,
      paddingBottom: Padding.xs,
      borderRadius: '100px',
      fontSize: Font.md.size,
      fontWeight: Font.md.weight,
      color: Color.opposite,
      backgroundColor: Color.text.link,
      boxShadow: Shadow.card.md,
    },
  }),
)
