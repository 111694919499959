import React, {
  ReactElement, useCallback, useState, Suspense,
} from 'react'
import { IArrayProps } from './types'
import {
  StyledArrayWrapper,
  StyledArrayColumn,
  StyledArrayColumnWrapper,
  StyledArrayHeader,
  StyledAddItemButton,
} from './styled'
import ConfigurationFieldsProvider from '../ConfigurationFieldsProvider'
import { camelCaseToCapitalizesText } from '../../../utils/string'
import { cloneArrayDeep, cloneDeep } from '../../../utils/object'
import { StyledIcon } from '../Element/styled'
import arrowDown from '../../../assets/images/icons/arrowDown.svg'
import { Font } from '../../../theme'

const ACArray: React.FC<IArrayProps> = (
  {
    fieldName,
    childrenFieldName = 'item',
    childrenFieldType,
    childrenElementType,
    configs,
    onChange,
    theme,
  },
): ReactElement => {
  const [open, setOpen] = useState(false)

  const switchOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const localOnChange = (configValue: any, index: number) => {
    const newConfigs = configs ? cloneArrayDeep(configs) : []
    newConfigs[index] = configValue
    onChange(newConfigs)
  }

  const addItem = () => {
    const newConfigs = configs ? cloneArrayDeep(configs) : []
    newConfigs.push(configs?.[0] ? cloneDeep(configs[0]) : {})
    onChange(newConfigs)
  }

  const removeItem = (index: number) => {
    const newConfigs = configs ? cloneArrayDeep(configs) : []
    newConfigs.splice(index, 1)
    onChange(newConfigs)
  }

  return (
    <StyledArrayWrapper>
      <StyledArrayHeader onClick={switchOpen}>
        {camelCaseToCapitalizesText(fieldName)}
        <StyledIcon
          theme={{
            opened: open,
          }}
          onClick={switchOpen}
          icon={arrowDown}
          size={Font.md.sizeNumber}
        />
      </StyledArrayHeader>
      { open && (
        <Suspense fallback={<div />}>
          <StyledArrayColumnWrapper>
            <StyledArrayColumn>
              {configs?.map(
                (config, index) => (
                  <ConfigurationFieldsProvider
                    key={index}
                    fieldType={childrenFieldType}
                    elementType={childrenElementType}
                    fieldName={`${childrenFieldName} [${index}]`}
                    configValue={configs?.[index]}
                    theme={theme}
                    onChange={(configValue) => localOnChange(configValue, index)}
                    onRemove={() => removeItem(index)}
                  />
                ),
              )}
            </StyledArrayColumn>
            <StyledAddItemButton onClick={addItem}>
              + Add New
              {` ${childrenFieldName}`}
            </StyledAddItemButton>
          </StyledArrayColumnWrapper>
        </Suspense>
      )}
    </StyledArrayWrapper>
  )
}

export default ACArray
