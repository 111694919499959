import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Padding, Transition } from '../../theme'
import Icon from '../Icon'
import { IStyledProps } from '../../models/types/helper/styledComponents'
import { IIconTheme } from './types'

export const StyledAccordionItem = styled(Box)`
  width: 100%;
  background-color: ${Color.opposite};
`

export const StyledIcon = styled(Icon)<IStyledProps<IIconTheme>>`
  transform: ${({ theme }) => (theme.opened ? 'rotate(180deg)' : 'none')};
  transition: ${Transition.default};
`

export const StyledAccordionHeader = styled(Box)`
  width: 100%;
  padding: ${Padding.xs} ${Padding.sm};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const StyledAccordionContent = styled(Box)`
  width: 100%;
  padding: ${Padding.sm} ${Padding.sm} ${Padding.md} ${Padding.sm};
  position: relative;
  &::before {
    content: "";
    height: 0.5px;
    background-color: ${Color.text.first};
    position: absolute;
    top: 0;
    left: ${Padding.xs};
    right: ${Padding.xs};
    opacity: 0.2;
  }
`
