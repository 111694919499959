import React, { ReactElement } from 'react'
import SeveralOptionsWithFeatures from './SeveralOptionsWithFeatures'
import {
  StyledBox,
} from './styled'

const Index: React.FC = (): ReactElement => (
  <StyledBox>
    <SeveralOptionsWithFeatures />
  </StyledBox>
)

export default Index
