import React, {
  ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { IConfigNavigationProps, IMenuItem, INavigationItemData } from './types'
import Tooltip from '../../../../../components/Tooltips/Tooltip'
import {
  StyledConfigNavigation,
  StyledConfigNavigationItem,
  StyledConfigNavigationTitle,
  StyledConfigNavigationSubtitle,
  StyledConfigNavigationDivider,
  StyledMenuItemPoint,
  StyledMenuItemPointWrapper,
  StyledMenuItemLabelWrapper,
  StyledMenuItemSettingsWrapper,
  StyledMenuItem,
  StyledMenuItemLeftBlock,
  StyledMenuAddItem,
  StyledMenuAddItemLabelWrapper,
} from './styled'
import {
  ApplicationKey, ApplicationKeyType,
  configNavigationItems,
} from './data'
import { IRefMethodsMenu } from '../../../../../components/Menu/types'
import Menu from '../../../../../components/Menu'
import { Font, PaddingNumber } from '../../../../../theme'
import Icon from '../../../../../components/Icon'
import settingsIcon from '../../../../../assets/images/icons/settings.svg'
import { RootState } from '../../../../../store'
import { getConfigs, getCurrentConfigsKeys } from '../../../../../store/slices/configs'
import { ConfigName } from '../../../../../models/enums/ConfigName'
import { getCurrentApplicationName } from '../../../../../store/slices/application'
import {
  DefLinksConfigProvider,
  MonetizationConfigProvider,
  ScreenStyleConfigProvider,
} from '../../../../../services/ConfigurationProviders'
import { SubscriptionState } from '../../../../../models/enums/OtherConfigsKeys/keys/SubscriptionState'

const ConfigNavigation: React.FC<IConfigNavigationProps> = (
  {
    style,
    className,
  },
): ReactElement => {
  const titleRefs = useRef<Array<RefObject<HTMLHeadingElement>>>(
    Array.from({ length: configNavigationItems.length }, () => React.createRef()),
  )
  const menuRefs = useRef<Array<RefObject<IRefMethodsMenu>>>(
    Array.from({ length: configNavigationItems.length }, () => React.createRef()),
  )

  const [titleWidths, setTitleWidths] = useState<Array<number | undefined>>([])
  const [showTooltip, setShowTooltip] = useState<boolean>(true)
  const [currentNavigationItem, setCurrentNavigationItem] = useState<INavigationItemData | null>(null)

  const configs = useSelector((state: RootState) => getConfigs(state))
  const currentConfigsKeys = useSelector((state: RootState) => getCurrentConfigsKeys(state))
  const appName = useSelector((state: RootState) => getCurrentApplicationName(state.application))

  const menuItems = useMemo<Array<IMenuItem>>(() => {
    if (currentNavigationItem === null) {
      return []
    }
    if (currentNavigationItem.key === ApplicationKey) {
      return []
    }
    const currentKey = currentConfigsKeys[currentNavigationItem.key]
    return configs[currentNavigationItem.key]?.map((config) => ({
      key: config.key,
      data: config.data,
      isCurrent: currentKey === config.key,
    })) ?? []
  }, [configs, currentConfigsKeys, currentNavigationItem])

  const getItemSubtitle = useCallback((itemKey: ConfigName | ApplicationKeyType): string => {
    switch (itemKey) {
      case ApplicationKey:
        return appName ?? 'unknown'
      default:
        return currentConfigsKeys[itemKey] ?? 'unknown' // TODO: unknown
    }
  }, [appName, currentConfigsKeys])

  const setNavigationItemWidth = useCallback(() => {
    setTitleWidths(
      titleRefs.current.map(
        (titleRef) => titleRef?.current?.offsetWidth,
      ),
    )
  }, [])

  useEffect(
    () => {
      setNavigationItemWidth()
      setTimeout(
        () => setNavigationItemWidth(),
        100,
      )
    },
    [setNavigationItemWidth, titleRefs],
  )

  const handleOpenMenu = useCallback((index: number, item: INavigationItemData) => {
    setCurrentNavigationItem(item)
    if (!menuRefs?.current?.[index]?.current?.isOpen()) {
      menuRefs?.current?.[index]?.current?.switchOpen()
      setShowTooltip(false)
    }
  }, [])

  const handleCloseMenu = useCallback((index: number) => {
    if (menuRefs?.current?.[index]?.current?.isOpen()) {
      menuRefs?.current?.[index]?.current?.switchOpen()
    }
  }, [])

  const onCloseMenu = useCallback(() => {
    setTimeout(() => {
      if (menuRefs?.current?.every((ref) => !ref.current?.isOpen())) {
        setShowTooltip(true)
      }
    })
  }, [])

  const onSelectItem = useCallback((
    index: number,
    menuItem: IMenuItem,
  ) => {
    const newKey = menuItem.key
    switch (currentNavigationItem?.key) {
      case ConfigName.DEF_LINK_CONFIG:
        DefLinksConfigProvider.shared.currentKey = newKey
        break
      case ConfigName.MONETIZATION_CONFIG:
        MonetizationConfigProvider.shared.currentKey = newKey
        break
      case ConfigName.LIFE_CYCLE_CONFIG:
        MonetizationConfigProvider.shared.currentHook = newKey
        break
      case ConfigName.SUBSCRIPTION_STATE_CONFIG:
        MonetizationConfigProvider.shared.currentSubscriptionState = newKey as SubscriptionState
        break
      case ConfigName.SCREEN_STYLE_CONFIG:
        ScreenStyleConfigProvider.shared.currentKey = newKey
        break
      case ApplicationKey:
      default:
        break
    }
    handleCloseMenu(index)
  }, [currentNavigationItem?.key, handleCloseMenu])

  return (
    <StyledConfigNavigation style={style} className={className}>
      { configNavigationItems.map((item, index) => (
        <React.Fragment key={index}>
          <Menu
            ref={menuRefs?.current?.[index]}
            toOpenByButton={false}
            onClose={onCloseMenu}
            topMargin={PaddingNumber.sm}
            position={index >= configNavigationItems.length - 2 ? 'right' : 'left'}
            openButton={(
              <Tooltip disabled={!showTooltip} title={getItemSubtitle(item.key)}>
                <StyledConfigNavigationItem
                  onClick={() => handleOpenMenu(index, item)}
                  theme={{
                    width: titleWidths[index],
                  }}
                >
                  <StyledConfigNavigationTitle ref={titleRefs.current[index]}>
                    {item.title}
                  </StyledConfigNavigationTitle>
                  <StyledConfigNavigationSubtitle>
                    {getItemSubtitle(item.key)}
                  </StyledConfigNavigationSubtitle>
                </StyledConfigNavigationItem>
              </Tooltip>
            )}
          >
            { menuItems.map((menuItem) => (
              <StyledMenuItem
                key={menuItem.key}
                onClick={() => onSelectItem(index, menuItem)}
              >
                <StyledMenuItemLeftBlock>
                  <StyledMenuItemPointWrapper>
                    { menuItem.isCurrent && <StyledMenuItemPoint /> }
                  </StyledMenuItemPointWrapper>
                  <StyledMenuItemLabelWrapper>
                    { menuItem.key }
                  </StyledMenuItemLabelWrapper>
                </StyledMenuItemLeftBlock>
                <StyledMenuItemSettingsWrapper>
                  <Icon
                    icon={settingsIcon}
                    size={Font.lg.sizeNumber}
                    padding={PaddingNumber.xxs}
                    onClick={() => {}}
                  />
                </StyledMenuItemSettingsWrapper>
              </StyledMenuItem>
            ))}
            <StyledMenuAddItem
              onClick={() => handleCloseMenu(index)}
            >
              <StyledMenuAddItemLabelWrapper>
                + Add New Item
              </StyledMenuAddItemLabelWrapper>
            </StyledMenuAddItem>
          </Menu>
          { index !== configNavigationItems.length - 1 && (
            <StyledConfigNavigationDivider>
              –
            </StyledConfigNavigationDivider>
          )}
        </React.Fragment>
      ))}
    </StyledConfigNavigation>
  )
}

export default ConfigNavigation
