import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Padding } from '../../theme'
import { hexOpacity } from '../../utils/hex'

export const StyledMenuItem = styled(Box)`
  width: 100%;
  background-color: ${Color.opposite};
  user-select: none;
  white-space: nowrap;
  padding: ${Padding.xs} ${Padding.sm};
  cursor: pointer;
  &:hover {
    background-color: ${Color.second}${hexOpacity(0.2)};
  }
`
