import React, { ReactElement } from 'react'
import PaywallConfigurationPanel from './PaywallConfigurationPanel'
import PaywallPreviewPanel from './PaywallPreviewPanel'
import PaywallSettingsPanel from './PaywallSettingsPanel'
import {
  StyledPageWrapper,
  StyledPage,
  PaywallConfigurationPanelWrapper,
  PaywallPreviewPanelWrapper,
  PaywallSettingsPanelWrapper,
} from './styled'

const PaywallEditor: React.FC = (): ReactElement => (
  <StyledPageWrapper
    height="100vh"
    width="100vw"
  >
    <StyledPage>
      <PaywallConfigurationPanelWrapper>
        <PaywallConfigurationPanel />
      </PaywallConfigurationPanelWrapper>
      <PaywallPreviewPanelWrapper>
        <PaywallPreviewPanel />
      </PaywallPreviewPanelWrapper>
      <PaywallSettingsPanelWrapper>
        <PaywallSettingsPanel />
      </PaywallSettingsPanelWrapper>
    </StyledPage>
  </StyledPageWrapper>
)

export default PaywallEditor
