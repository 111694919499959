import mainImage from '../../../../../../assets/images/paywallView/mainImage/mainImage@2x.png'
import closeIcon from '../../../../../../assets/images/paywallView/closeIcon/closeIcon@2x.png'
import starIcon from '../../../../../../assets/images/paywallView/starIcon/starIcon@2x.png'
import checkIcon from '../../../../../../assets/images/paywallView/checkIcon/checkIcon@2x.png'
import { ILabel, IScreen } from '../../../../../../models/types/paywallConfig/paywallElements'
import { IImageDefault } from '../../../../../../components/PaywallPreviewElements/Image/types'
import { IButtonDefault } from '../../../../../../components/PaywallPreviewElements/Buttons/DefaultButton/types'
import { IFeatureItemDefault } from '../../../../../../components/PaywallPreviewElements/FeatureItem/types'
import { resize } from '../../../utils/resize'

export class DefaultConfigurationProvider {
  static getScreenConfig(config: any): IScreen {
    return {
      backgroundColor: config?.theme?.colors?.opposite,
    }
  }

  static getMainImageConfig(config: any): IImageDefault {
    return {
      backgroundColor: config?.theme?.colors?.opposite,
      image: mainImage,
    }
  }

  static getCloseIconConfig(config: any): IImageDefault {
    return {
      tintColor: config?.theme?.colors?.opposite ?? '#FFFFFF',
      image: closeIcon,
    }
  }

  static getRestoreLabelConfig(config: any): ILabel {
    return {
      text: 'Restore',
      color: config?.theme?.colors?.opposite ?? '#ffffff',
      font: config?.theme?.fonts?.link ?? {
        size: resize(16),
        weight: 400,
      },
    }
  }

  static getTitleLabelConfig(config: any): ILabel {
    return {
      color: config?.theme?.colors?.textH1 ?? '#000000',
      font: config?.theme?.fonts?.h1 ?? {
        size: resize(20),
        weight: 600,
      },
    }
  }

  static getFeatureConfig(config: any): IFeatureItemDefault {
    return {
      icon: {
        image: starIcon,
        tintColor: config?.theme?.colors?.first,
      },
      title: {
        text: 'Feature',
        color: config?.theme?.colors?.textH2 ?? '#000000',
        font: config?.theme?.fonts?.h2 ?? {
          size: resize(16),
          weight: 500,
        },
      },
    }
  }

  static getIAPButtonConfig(config: any, product: any, index: number): IButtonDefault {
    return {
      height: resize(64),
      backgroundColor: config?.theme?.colors?.opposite,
      cornerRadius: resize(12),
      border: index === 0
        ? {
          width: resize(2),
          color: config?.theme?.colors?.first ?? '#000000',
        }
        : {
          width: resize(1),
          color: config?.theme?.colors?.textSecond ?? '#d9d9d9',
        },
      title: {
        text: product.introductoryOffer && product.introductoryOffer?.price == null
          ? `${product.introductoryOffer.localizedPeriod} free`
          : `${product.localizedPeriod}: ${product.price}`,
        color: config?.theme?.colors?.textFirst ?? '#000000',
        font: config?.theme?.fonts?.medium ?? {
          size: resize(16),
          weight: 500,
        },
      },
      subtitle: {
        text: (product.introductoryOffer && product.introductoryOffer?.price == null)
          ? `then ${product.price}/${product.localizedPeriod}`
          : '',
        color: config?.theme?.colors?.textFirst ?? '#000000',
        font: config?.theme?.fonts?.small ?? {
          size: resize(12),
          weight: 400,
        },
        isEnabled: (product.introductoryOffer && product.introductoryOffer?.price == null) ?? false,
      },
      icon: {
        image: index === 0 ? checkIcon : undefined,
        cornerRadius: resize(12),
        tintColor: index === 0
          ? config?.theme?.colors?.first ?? '#000000'
          : undefined,
        border: index === 0
          ? undefined
          : {
            width: 1,
            color: config?.theme?.colors?.textSecond ?? '#d9d9d9',
          },
      },
    }
  }

  static getPurchaseButtonConfig(config: any): IButtonDefault {
    return {
      backgroundColor: config?.theme?.colors?.first,
      height: resize(64),
      cornerRadius: resize(32),
      title: {
        text: 'Continue',
        color: config?.theme?.colors?.opposite ?? '#000000',
        font: config?.theme?.fonts?.medium ?? {
          size: resize(16),
          weight: 500,
        },
      },
      subtitle: {
        color: config?.theme?.colors?.opposite ?? '#000000',
        font: config?.theme?.fonts?.small ?? {
          size: resize(12),
          weight: 400,
        },
      },
    }
  }

  static getPrivacyLabelConfig(config: any): ILabel {
    return {
      text: 'Privacy Policy',
      color: config?.theme?.colors?.textLink ?? '#a1a1a1',
      font: config?.theme?.fonts?.link ?? {
        size: resize(12),
        weight: 400,
      },
    }
  }

  static getTermsLabelConfig(config: any): ILabel {
    return {
      text: 'Terms of Use',
      color: config?.theme?.colors?.textLink ?? '#a1a1a1',
      font: config?.theme?.fonts?.link ?? {
        size: resize(12),
        weight: 400,
      },
    }
  }
}
