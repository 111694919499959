const config = {
  theme: {
    colors: {
      first: '#f24a65',
      second: '#959595',
      opposite: '#ffffff',
      textH1: '#000000',
      textH2: '#123123',
      textH3: '#553322',
      textFirst: '#111111',
      textSecond: '#b1b1b1',
      textLink: '#a0a0a0',
    },
    fonts: {
      h1: {
        weight: 700,
        size: 20,
      },
      h2: {
        weight: 600,
        size: 18,
      },
      h3: {
        weight: 500,
        size: 14,
      },
      large: {
        weight: 600,
        size: 18,
      },
      medium: {
        weight: 500,
        size: 14,
      },
      small: {
        weight: 400,
        size: 12,
      },
      link: {
        weight: 400,
        size: 16,
      },
    },
  },
  screen: {
    backgroundColor: '#ffffff',
  },
  mainImage: {
    id: 'mainImage',
  },
  restore: {
    opacity: 0.6,
  },
  close: {
    opacity: 0.6,
  },
  title: {
    isEnabled: false,
  },
  features: {
    elements: [
      {
        title: {
          text: 'Trendy makeup looks',
        },
        icon: {
          id: 'featureFirst',
        },
      },
      {
        title: {
          text: 'Must-have retouch tools',
        },
        icon: {
          id: 'featureSecond',
        },
      },
      {
        title: {
          text: 'Unlimited selfie enhancement',
        },
        icon: {
          id: 'featureThird',
        },
      },
    ],
  },
  IAPBlock: {
    defaultButton: {
      cornerRadius: 10,
    },
  },
  purchaseButton: {
    title: {
      text: 'Continue',
    },
    cornerRadius: 26,
  },
  privacy: {
    opacity: 0.6,
  },
  terms: {
    opacity: 0.6,
  },
}

export default config
