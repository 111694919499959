import React, {
  ReactElement, useCallback, useState, useImperativeHandle, forwardRef, useEffect,
} from 'react'
import { ClickAwayListener } from '@mui/material'
import {
  IMenuProps,
  IRefMethodsMenu,
} from './types'
import {
  StyledMenu,
  StyledMenuWrapper,
} from './styled'
import { usePrevious } from '../../hooks/usePrevious'

const Menu = forwardRef<IRefMethodsMenu, IMenuProps>((
  {
    children,
    style,
    className,
    toOpenByButton = true,
    openButton,
    topMargin,
    position = 'left',
    onClose,
  },
  ref,
): ReactElement => {
  const [open, setOpen] = useState(false)
  const prevOpen = usePrevious(open)

  const menuRef = React.useRef<HTMLInputElement>(null)

  const onSwitchOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  useEffect(() => {
    if (prevOpen && !open) {
      onClose?.()
    }
  }, [onClose, open, prevOpen])

  useImperativeHandle(
    ref,
    () => ({
      switchOpen() {
        onSwitchOpen()
      },
      isOpen() {
        return open
      },
    }),
  )

  return (
    <StyledMenuWrapper onClick={() => toOpenByButton && onSwitchOpen()}>
      {openButton}
      { open && (
        <ClickAwayListener onClickAway={() => open && onSwitchOpen()}>
          <StyledMenu
            theme={{
              topMargin,
              position,
            }}
            ref={menuRef}
            style={style}
            className={className}
          >
            <div>
              {children}
            </div>
          </StyledMenu>
        </ClickAwayListener>
      )}
    </StyledMenuWrapper>
  )
})

export default Menu
