import { PayloadAction } from '@reduxjs/toolkit'
import { ScreenStyleConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/ScreenStyleConfigKey'
import { ICreateConfigActionPayload, IState, IUpdateConfigActionPayload } from './types'
import { StoreError } from '../../../../models/enums/Errors'
import { getScreenStyleConfig } from '../../../../models/configs/screenStyleConfig'
import { DEFAULT_CONFIG_NAME } from './initialState'
import { getItem, storeItem } from '../../../../services/LocalStorage'
import { ConfigName } from '../../../../models/enums/ConfigName'
import { getConfigByKey, getConfigIndexByKey } from './getters'

export const saveStateReducer = (state: IState) => {
  storeItem(ConfigName.SCREEN_STYLE_CONFIG, state)
}

export const loadStateReducer = (state: IState) => {
  const storedState = getItem(ConfigName.SCREEN_STYLE_CONFIG)
  if (storedState !== null) {
    return storedState as IState
  }
  return state
}

export const setCurrentConfigKeyReducer = (state: IState, action: PayloadAction<ScreenStyleConfigKey | string>) => {
  state.currentConfigKey = action.payload

  saveStateReducer(state)

  return state
}

export const createConfigReducer = (state: IState, action: PayloadAction<ICreateConfigActionPayload>) => {
  if (getConfigByKey(state, action.payload.key) !== undefined) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }
  state.configs.push({
    key: action.payload.key,
    name: action.payload.name ?? DEFAULT_CONFIG_NAME,
    data: action.payload.data ?? getScreenStyleConfig(action.payload.name ?? DEFAULT_CONFIG_NAME),
  })

  saveStateReducer(state)

  return state
}

export const updateConfigReducer = (state: IState, action: PayloadAction<IUpdateConfigActionPayload>) => {
  const index = getConfigIndexByKey(state, action.payload.key)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs.splice(index, 1, {
    key: action.payload.key,
    name: action.payload.name ?? state.configs[index].name,
    data: action.payload.data ?? getScreenStyleConfig(action.payload.name ?? DEFAULT_CONFIG_NAME),
  })

  saveStateReducer(state)

  return state
}

export const deleteConfigReducer = (state: IState, action: PayloadAction<ScreenStyleConfigKey | string>) => {
  const index = getConfigIndexByKey(state, action.payload)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs.splice(index, 1)

  saveStateReducer(state)

  return state
}
