import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Padding } from '../../../../../theme'
import { StyledFont, Unselectable } from '../../../../../styled'
import { IStyledProps } from '../../../../../models/types/helper/styledComponents'
import { IStyledConfigNavigationItemTheme } from './types'
import MenuItem from '../../../../../components/MenuItem'

export const StyledConfigNavigation = styled(Box)`
  width: 100%;
  padding: ${Padding.md} ${Padding.md} ${Padding.sm} ${Padding.md};
  position: relative;
  display: flex;
  &::after {
    content: "";
    height: 0.5px;
    background-color: ${Color.text.first};
    position: absolute;
    bottom: 0;
    left: ${Padding.xs};
    right: ${Padding.xs};
    opacity: 0.2;
  }
`

export const StyledConfigNavigationElement = styled.h2`
  ${StyledFont.mdBold};
  color: ${Color.text.link};
  white-space: nowrap;
  margin: 0;
`

export const StyledConfigNavigationTitle = styled(StyledConfigNavigationElement)`

`

export const StyledConfigNavigationItem = styled(Box)<IStyledProps<IStyledConfigNavigationItemTheme>>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  ${Unselectable};
  width: ${({ theme }) => (theme.width ? `${theme.width}px` : 'auto')};
`

export const StyledConfigNavigationSubtitle = styled.span`
  ${StyledFont.xs};
  color: ${Color.text.second};
  text-overflow: ellipsis;
  text-align: right;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`

export const StyledConfigNavigationDivider = styled(StyledConfigNavigationElement)`
  flex-grow: 1;
  text-align: center;
`

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledMenuAddItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledMenuAddItemLabelWrapper = styled(Box)`
  ${StyledFont.mdLight};
  color: ${Color.text.second};
`

export const StyledMenuItemLeftBlock = styled(Box)`
  display: flex;
  align-items: center;
`

export const StyledMenuItemPointWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: -${Padding.sm};
  width: calc(${Padding.sm} + ${Padding.xs} + ${Padding.xs});
`

export const StyledMenuItemPoint = styled(Box)`
  width: ${Padding.xs};
  height: ${Padding.xs};
  border-radius: calc(${Padding.xs} / 2);
  background-color: ${Color.first};
  margin-left: ${Padding.sm};
`

export const StyledMenuItemLabelWrapper = styled(Box)`
  ${StyledFont.mdLight};
  color: ${Color.text.first};
`

export const StyledMenuItemSettingsWrapper = styled(Box)`
  margin-left: ${Padding.xs};
`
