import styled from '@emotion/styled'
import { StyledFont } from '../../../styled'
import { Color, Padding } from '../../../theme'

export const StyledArrayWrapper = styled.div`
`

export const StyledArrayHeader = styled.div`
  ${StyledFont.smBold};
  margin-bottom: ${Padding.sm};
  margin-top: ${Padding.sm};
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: italic;
`

export const StyledArrayColumnWrapper = styled.div`
  width: 100%;
  margin-left: ${Padding.md};
`

export const StyledArrayColumn = styled.div`
  width: min-content;
`

export const StyledAddItemButton = styled.div`
  ${StyledFont.smBold};
  color: ${Color.text.second};
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
`
