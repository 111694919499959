import { PayloadAction } from '@reduxjs/toolkit'
import { AppConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/AppConfigKey'
import { ICreateConfigActionPayload, IState, IUpdateConfigActionPayload } from './types'
import { getConfigByKey, getConfigIndexByKey } from './getters'
import { StoreError } from '../../../../models/enums/Errors'
import { DEFAULT_CONFIG_DATA } from './initialState'
import { getItem, storeItem } from '../../../../services/LocalStorage'
import { ConfigName } from '../../../../models/enums/ConfigName'

export const saveStateReducer = (state: IState) => {
  storeItem(ConfigName.APP_CONFIG, state)
}

export const loadStateReducer = (state: IState) => {
  const storedState = getItem(ConfigName.APP_CONFIG)
  if (storedState !== null) {
    return storedState as IState
  }
  return state
}

export const setCurrentConfigKeyReducer = (state: IState, action: PayloadAction<AppConfigKey | string>) => {
  state.currentConfigKey = action.payload

  saveStateReducer(state)

  return state
}

export const createConfigReducer = (state: IState, payload: ICreateConfigActionPayload) => {
  if (getConfigByKey(state, payload.key) !== undefined) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }
  state.configs.push({
    key: payload.key,
    data: payload.data ?? DEFAULT_CONFIG_DATA,
  })

  saveStateReducer(state)

  return state
}

export const updateConfigReducer = (state: IState, action: PayloadAction<IUpdateConfigActionPayload>) => {
  const index = getConfigIndexByKey(state, action.payload.key)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs.splice(index, 1, {
    key: action.payload.key,
    data: action.payload.data,
  })

  saveStateReducer(state)

  return state
}

export const deleteConfigReducer = (state: IState, payload: AppConfigKey | string) => {
  const index = getConfigIndexByKey(state, payload)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs.splice(index, 1)

  saveStateReducer(state)

  return state
}
