export enum ConfigName {
  DEF_LINKS_CONFIG = 'defLinksConfig',
  DEF_LINK_CONFIG = 'defLinkConfig',
  DEF_LINKS_CONDITIONS = 'defLinksConditions',
  DEF_LINK_CONDITION = 'defLinkCondition',
  APP_CONFIG = 'appConfig',
  MONETIZATION_CONFIG = 'monetizationConfig',
  LIFE_CYCLE_CONFIG = 'lifeCycleConfig',
  SUBSCRIPTION_STATE_CONFIG = 'subscriptionStateConfig',
  SCREEN_STYLE_CONFIG = 'screenStyleConfig',
}
