import React, { ReactElement } from 'react'
import { IElementConfigurationPlateProps } from './types'
import { ElementType } from '../../../../../models/enums/StyleConfigAttributesTypes/ElementType'
import { PaywallElement } from '../../../../../models/paywallConfigurationFields'
import ThemePlate from './Plates/ThemePlate'
import DefaultElementPlate from './Plates/DefaultElementPlate'

const ElementConfigurationPlate: React.FC<IElementConfigurationPlateProps> = (
  {
    type,
    config,
    theme,
    onChange,
  },
): ReactElement => {
  switch (type) {
    case ElementType.theme:
      return (
        <ThemePlate
          plateElements={PaywallElement[ElementType.theme]}
          config={config}
          onChange={onChange}
        />
      )
    default:
      return (
        <DefaultElementPlate
          plateElements={PaywallElement[type]}
          config={config}
          theme={theme}
          onChange={onChange}
        />
      )
  }
}

export default ElementConfigurationPlate
