import React, {
  ReactElement, useCallback, useState, Suspense, useImperativeHandle, forwardRef,
} from 'react'
import arrowDown from '../../assets/images/icons/arrowDown.svg'
import {
  IPaywallConfigurationPanelProps,
  IRefMethodsAccordionItem,
} from './types'
import {
  StyledAccordionItem,
  StyledAccordionHeader,
  StyledAccordionContent,
  StyledIcon,
} from './styled'

const AccordionItem = forwardRef<IRefMethodsAccordionItem, IPaywallConfigurationPanelProps>((
  {
    children,
    HeaderTextComponent,
    style,
    className,
  },
  ref,
): ReactElement => {
  const [open, setOpen] = useState(false)

  const itemRef = React.useRef<HTMLInputElement>(null)

  const onSwitchOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      open() {
        if (!open) {
          onSwitchOpen()
        }
      },
      scrollIntoView() {
        itemRef.current?.scrollIntoView()
      },
    }),
  )

  return (
    <StyledAccordionItem ref={itemRef} style={style} className={className}>
      <StyledAccordionHeader onClick={onSwitchOpen}>
        {HeaderTextComponent}
        <StyledIcon
          theme={{
            opened: open,
          }}
          icon={arrowDown}
          size={22}
        />
      </StyledAccordionHeader>
      { open && (
        <Suspense fallback={<div />}>
          <StyledAccordionContent>
            {children}
          </StyledAccordionContent>
        </Suspense>
      )}
    </StyledAccordionItem>
  )
})

export default AccordionItem
