import { getScreenStyleConfig } from '../../../../models/configs/screenStyleConfig'
import { ScreenStyleConfigKeys } from '../../../../models/enums/ScreenStyleConfigKeys'
import { ScreenStyleConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys'
import { IState } from './types'

export const DEFAULT_CONFIG_NAME = ScreenStyleConfigKeys.SeveralOptionsWithFeatures
const defaultConfig = getScreenStyleConfig(DEFAULT_CONFIG_NAME)

const initialState: IState = {
  currentConfigKey: ScreenStyleConfigKey.DEFAULT,
  configs: [
    {
      key: ScreenStyleConfigKey.DEFAULT,
      name: DEFAULT_CONFIG_NAME,
      data: defaultConfig,
    },
  ],
}

export default initialState
