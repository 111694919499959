import React, { ReactElement } from 'react'
import { StyleAttributeType } from '../../models/enums/StyleConfigAttributesTypes/StyleAttributeType'
import { IConfigurationFieldsProviderProps } from './types'
import {
  ACColor,
  ACFont,
  ACBorder,
  ACCheckbox,
  ACNumber,
  ACText,
} from './index'
import ACElement from './Element'
import { ElementType } from '../../models/enums/StyleConfigAttributesTypes/ElementType'
import StyledError from '../../styled/StyledError'
import ACArray from './Array'

const ConfigurationFieldsProvider: React.FC<IConfigurationFieldsProviderProps> = (
  {
    fieldType,
    elementType,
    fieldName,
    configValue,
    theme,
    onChange,
    fieldNameFirst = true,
    onRemove,
  },
): ReactElement => {
  switch (fieldType) {
    case StyleAttributeType.color:
      return (
        <ACColor
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
        />
      )
    case StyleAttributeType.font:
      return (
        <ACFont
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
        />
      )
    case StyleAttributeType.border:
      return (
        <ACBorder
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
        />
      )
    case StyleAttributeType.boolean:
      return (
        <ACCheckbox
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
        />
      )
    case StyleAttributeType.float:
      return (
        <ACNumber
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
        />
      )
    case StyleAttributeType.percentage:
      return (
        <ACNumber
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
          max={1}
          delta={0.01}
        />
      )
    // TODO: return <ACSelect fieldName={fieldName} configValue={configValue} theme={theme} />
    case StyleAttributeType.id:
    case StyleAttributeType.text:
    case StyleAttributeType.unknown:
      return (
        <ACText
          fieldName={fieldName}
          configValue={configValue}
          theme={theme}
          onChange={onChange}
          fieldNameFirst={fieldNameFirst}
        />
      )
    case StyleAttributeType.element:
      return (
        <ACElement
          fieldName={fieldName}
          config={configValue}
          elementType={elementType as ElementType}
          theme={theme}
          onChange={onChange}
          onRemove={onRemove}
        />
      )
    case StyleAttributeType.array:
      return (
        <ACArray
          fieldName={fieldName}
          configs={configValue}
          childrenFieldName="Item"
          childrenFieldType={StyleAttributeType.element}
          childrenElementType={elementType as ElementType}
          theme={theme}
          onChange={onChange}
        />
      )
    default:
      console.log('ConfigurationFieldsProvider Error')
      return (
        <div>
          <StyledError> ConfigurationFieldsProvider Error </StyledError>
        </div>
      )
  }
}

export default ConfigurationFieldsProvider
