import styled from '@emotion/styled'
import { StyledFont } from '../../../../../styled'
import { Color } from '../../../../../theme'

export const StyledConfigElementTextWrapper = styled.span`
  display: flex;
  flex-direction: column;
`

export const StyledConfigElementTitle = styled.span`
  ${StyledFont.smBold};
  color: ${Color.text.first};
`

export const StyledConfigElementSubtitle = styled.span`
  ${StyledFont.xs};
  color: ${Color.text.second};
  text-transform: capitalize;
`
