import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Breakpoint, Color, Padding } from '../../theme'

export const StyledPageWrapper = styled(Box)`
  background: ${Color.second};
  margin: 0;
`

export const StyledPage = styled(Box)`
  padding: ${Padding.md};
  display: grid;
  grid-gap: ${Padding.sm};
  grid-template-columns: 550px 1fr 520px;
  max-width: 2200px;
  width: 100%;
  height: 100%;
  margin: auto;
`

export const PaywallSettingsPanelWrapper = styled(Box)`
  overflow: hidden;
  @media (max-width: ${Breakpoint.xl}) {
    display: none;
  }
`

export const PaywallConfigurationPanelWrapper = styled(Box)`
  overflow: hidden;
`

export const PaywallPreviewPanelWrapper = styled(Box)`
  overflow: hidden;
`
