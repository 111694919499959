import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Padding } from '../../../../../theme'
import { StyledFont } from '../../../../../styled'
import { IStyledProps } from '../../../../../models/types/helper/styledComponents'
import { INavigationItemWrapperTheme } from './types'

export const StyledNavigation = styled(Box)`
  width: 100%;
  position: absolute;
  display: flex;
  height: 54px;
  z-index: 120;
  &::after {
    content: "";
    height: 0.5px;
    background-color: ${Color.text.first};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
  }
`

export const StyledNavigationItemWrapper = styled.div<IStyledProps<INavigationItemWrapperTheme>>`
  position: relative;
  cursor: pointer;
  color: ${Color.text.link};
  flex-grow: 1;
  padding: ${Padding.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  &:hover {
    background-color: ${Color.text.link};
    color: ${Color.opposite};
  }
  &::after {
    content: "";
    height: 3px;
    background-color: ${Color.first};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: ${({ theme }) => (theme.isCurrent ? 'visible' : 'hidden')};
  }
`

export const StyledNavigationItem = styled.h3`
  ${StyledFont.mdBold};
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
