import { AES, enc } from 'crypto-ts'
import { cryptoSecret } from './config'

export const storeItem = (key: string, item: any) => {
  try {
    const encryptedString = AES.encrypt(JSON.stringify(item), cryptoSecret).toString()
    localStorage.setItem(key, encryptedString)
    return true
  } catch {
    return false
  }
}

export const getItem = (key: string) => {
  try {
    const encryptedString = localStorage.getItem(key)
    if (encryptedString !== null) {
      const bytes = AES.decrypt(encryptedString, cryptoSecret)
      const json = bytes.toString(enc.Utf8)
      return JSON.parse(json)
    }
    return null
  } catch {
    return null
  }
}
