import { IDefLinkCondition, IState } from './types'
import { DefLinkConditionKeys } from '../../../../models/enums/OtherConfigsKeys'
import { DefLinksConditionsKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/DefLinksConditionsKey'

export const DEFAULT_CONFIG_DATA: IDefLinkCondition = {
  [DefLinkConditionKeys.CAMPAIGN]: [],
  [DefLinkConditionKeys.MEDIA_SOURCES]: [],
}

export const CONFIG_DEFAULT = {
  key: DefLinksConditionsKey.PERMANENT,
  data: {},
}

const initialState: IState = {
  currentConfigKeys: {
    defLinksCondition: DefLinksConditionsKey.PERMANENT,
    defLinkCondition: null,
  },
  config: CONFIG_DEFAULT,
}

export default initialState
