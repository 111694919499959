export const filterObject = <T>(object: T): T => Object.entries(object).reduce<T>((acc, [key, value]) => {
  if (value !== undefined) {
    acc[key as keyof T] = value
  }
  return acc
}, {} as T)

export const deepEquals = (a: { [key: string]: any }, b: { [key: string]: any }): boolean => {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b
  if (a.prototype !== b.prototype) return false
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => deepEquals(a[k], b[k]))
}

export const isObject = (value: any): boolean => Object.prototype.toString.call(value) === '[object Object]'

export const cloneDeep = (value: any): object | Array<any> => {
  if (isObject(value)) {
    return Object.entries(value).reduce((acc, [key, childValue]) => {
      acc[key] = cloneDeep(childValue)
      return acc
    }, {} as { [key: string]: any })
  }
  if (Array.isArray(value)) {
    return value.map((childValue) => cloneDeep(childValue))
  }
  return value
}

export const cloneArrayDeep = (
  value: Array<any>,
): Array<any> => value.map(
  (childValue) => cloneDeep(childValue),
  {} as { [key: string]: any },
)
