import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { ITooltipProps } from './types'
import {
  StyledTooltip,
} from './styled'

const Tooltip: React.FC<ITooltipProps> = (
  {
    children,
    title,
    disabled = false,
  },
): ReactElement => {
  const [open, setOpen] = useState<boolean>(false)

  const onOpen = useCallback(() => {
    if (disabled) {
      return
    }
    setOpen(true)
  }, [disabled])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (disabled) {
      onClose()
    }
  }, [disabled, onClose])

  return (
    <StyledTooltip
      title={title}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      {children as ReactElement}
    </StyledTooltip>
  )
}

export default Tooltip
