import { configureStore } from '@reduxjs/toolkit'
import application from './slices/application'
import appConfig from './slices/configs/appConfig'
import screenStyleConfig from './slices/configs/screenStyleConfig'
import defLinksConfig from './slices/configs/defLinksConfig'
import defLinksConditions from './slices/configs/defLinksConditions'
import monetizationConfig from './slices/configs/monetizationConfig'

const store = configureStore({
  reducer: {
    application,
    appConfig,
    screenStyleConfig,
    defLinksConfig,
    defLinksConditions,
    monetizationConfig,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
