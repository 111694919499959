import { IAppConfigItem, IConfig, IState } from './types'
import { AppConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/AppConfigKey'

export const getConfigs = (
  state: IState,
): Array<IConfig> => state.configs

export const getConfigByKey = (
  state: IState,
  key: AppConfigKey | string,
): IAppConfigItem | undefined => state.configs
  .find((config: IConfig) => config.key === key)?.data

export const getConfigIndexByKey = (
  state: IState,
  key: AppConfigKey | string,
): number => state.configs
  .findIndex((config: IConfig) => config.key === key)

export const getCurrentConfig = (
  state: IState,
): IConfig | undefined => state.configs
  .find(
    (config: IConfig) => config.key === state.currentConfigKey,
  )

export const getCurrentConfigData = (
  state: IState,
): IAppConfigItem | undefined => state.configs
  .find(
    (config: IConfig) => config.key === state.currentConfigKey,
  )?.data

export const getCurrentConfigKey = (
  state: IState,
): AppConfigKey | string => state.currentConfigKey
