import { lazy } from 'react'

export enum ViewKey {
  GENERAL = 'GENERAL',
  TRANSLATIONS = 'TRANSLATIONS',
  MOCKUP = 'MOCKUP',
  HISTORY = 'HISTORY',
}

export const Views = {
  [ViewKey.GENERAL]: lazy(() => import('./views/General')),
  [ViewKey.TRANSLATIONS]: lazy(() => import('./views/Translations')),
  [ViewKey.MOCKUP]: lazy(() => import('./views/Mockup')),
  [ViewKey.HISTORY]: lazy(() => import('./views/History')),
}
