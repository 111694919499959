import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { safeArea } from '../../../../../utils/device'
import { resize } from '../../utils/resize'

export const MainImageView = styled(Box)`
  height: ${resize(392)}px;
`

export const CloseIconView = styled(Box)`
  position: absolute;
  top: ${safeArea.iphone('11').top};
  left: 0;
  padding: ${resize(12)}px;
  width: calc(${resize(24)}px + ${resize(12)}px * 2);
  height: calc(${resize(24)}px + ${resize(12)}px * 2);
`

export const RestoreLabelView = styled(Box)`
  position: absolute;
  top: ${safeArea.iphone('11').top};
  right: 0;
  padding: ${resize(12)}px;
`

export const TitleLabelView = styled(Box)`
  padding: ${resize(12)}px ${resize(16)}px 0;
  text-align: center;
`

export const FeaturesView = styled(Box)`
  padding: ${resize(16)}px ${resize(16)}px;
`

export const FeatureItemView = styled(Box)`
  padding: 0 ${resize(16)}px;
`

export const IAPBlockView = styled(Box)`
  padding: 0 ${resize(16)}px ${resize(12)}px;
`

export const IAPButtonView = styled(Box)`
  padding: ${resize(6)}px 0
`

export const PurchaseButtonView = styled(Box)`
  padding: ${resize(16)}px ${resize(16)}px ${resize(12)}px;
`

export const LinksView = styled(Box)`
  padding: 0 ${resize(16)}px ${resize(12)}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: ${resize(60)}px;
`
