import React, { ReactElement } from 'react'
import { IAccordionHeaderTextComponentProps } from './types'
import {
  StyledConfigElementTextWrapper,
  StyledConfigElementTitle,
  StyledConfigElementSubtitle,
} from './styled'

const AccordionHeaderTextComponent: React.FC<IAccordionHeaderTextComponentProps> = (
  {
    name,
    type,
    style,
    className,
  },
): ReactElement => (
  <StyledConfigElementTextWrapper style={style} className={className}>
    <StyledConfigElementTitle>
      {name}
    </StyledConfigElementTitle>
    <StyledConfigElementSubtitle>
      {type}
    </StyledConfigElementSubtitle>
  </StyledConfigElementTextWrapper>
)

export default AccordionHeaderTextComponent
