import { ConfigName } from '../../../../../models/enums/ConfigName'
import { INavigationItemData } from './types'

export type ApplicationKeyType = 'application'
export const ApplicationKey: ApplicationKeyType = 'application'

export const configNavigationItems: Array<INavigationItemData> = [
  {
    title: 'App Name',
    itemName: 'App',
    key: ApplicationKey,
  },
  {
    title: 'DefLinks',
    itemName: 'DefLink Config',
    key: ConfigName.DEF_LINK_CONFIG,
  },
  {
    title: 'Monetization',
    itemName: 'Monetization Config',
    key: ConfigName.MONETIZATION_CONFIG,
  },
  {
    title: 'LifeCycle',
    itemName: 'LifeCycle Hook Config',
    key: ConfigName.LIFE_CYCLE_CONFIG,
  },
  {
    title: 'Subscription',
    itemName: 'Subscription State Config',
    key: ConfigName.SUBSCRIPTION_STATE_CONFIG,
  },
  {
    title: 'Configuration',
    itemName: 'Screen Style Config',
    key: ConfigName.SCREEN_STYLE_CONFIG,
  },
]
