import React, {
  ReactElement, useCallback,
  useMemo,
} from 'react'
import { useSelector } from 'react-redux'
import {
  ACLabel,
  ACDefaultButton,
  ACSelectableButton,
  ACImage,
  ACFeatureItem,
  ACScreenView,
} from '../../../../../components/PaywallPreviewElements'
import {
  RelativeBox,
  FlexSpacer,
} from '../../../../../styled'
import {
  MainImageView,
  CloseIconView,
  RestoreLabelView,
  TitleLabelView,
  FeaturesView,
  FeatureItemView,
  IAPBlockView,
  IAPButtonView,
  PurchaseButtonView,
  LinksView,
} from './styled'
import { IAPService } from '../../../../../services/IAPService'
import {
  IButton, IFeatureItem, IImage, ILabel, IScreen,
} from '../../../../../models/types/paywallConfig/paywallElements'
import { IComponentConfig } from '../../../../../models/types/helper/componentConfig'
import { IImageDefault } from '../../../../../components/PaywallPreviewElements/Image/types'
import { IFeatureItemDefault } from '../../../../../components/PaywallPreviewElements/FeatureItem/types'
import { IButtonDefault } from '../../../../../components/PaywallPreviewElements/Buttons/DefaultButton/types'
import { DefaultConfigurationProvider } from './_providers/DefaultConfigurationProvider'
import { ElementName } from '../../../../../models/enums/Elements/ElementName'
import { FeaturesKey, IAPBlockKey } from '../../../../../models/enums/ConfigKeys'
import { MonetizationConfigProvider, ScreenStyleConfigProvider } from '../../../../../services/ConfigurationProviders'
import { PaywallConfig } from '../../../../../models/enums/OtherConfigsKeys'
import { cloneDeep } from '../../../../../utils/object'

const SeveralOptionsWithFeatures: React.FC = (): ReactElement => {
  const config = useSelector(() => ScreenStyleConfigProvider.shared.currentData)
  const paywallId = useSelector(
    () => MonetizationConfigProvider.shared.currentSubscriptionStateConfig?.[PaywallConfig.PAYWALL_ID],
  )

  const screenConfig = useMemo<IComponentConfig<IScreen, IScreen>>(() => ({
    element: config?.screen,
    default: DefaultConfigurationProvider.getScreenConfig(config),
  }), [config])

  const mainImageConfig = useMemo<IComponentConfig<IImage, IImageDefault>>(() => ({
    element: config?.mainImage,
    default: DefaultConfigurationProvider.getMainImageConfig(config),
  }), [config])

  const closeIconConfig = useMemo<IComponentConfig<IImage, IImageDefault>>(() => ({
    element: config?.close,
    default: DefaultConfigurationProvider.getCloseIconConfig(config),
  }), [config])

  const restoreLabelConfig = useMemo<IComponentConfig<ILabel, ILabel>>(() => ({
    element: config?.restore,
    default: DefaultConfigurationProvider.getRestoreLabelConfig(config),
  }), [config])

  const titleLabelConfig = useMemo<IComponentConfig<ILabel, ILabel>>(() => ({
    element: config?.title,
    default: DefaultConfigurationProvider.getTitleLabelConfig(config),
  }), [config])

  const featuresConfig = useMemo<Array<IComponentConfig<IFeatureItem, IFeatureItemDefault>>>(() => (
    config?.features?.elements?.map((feature: IFeatureItem) => ({
      element: feature,
      default: DefaultConfigurationProvider.getFeatureConfig(config),
    }))
  ), [config])

  const iapButtonsConfig = useMemo<Array<IComponentConfig<IButton, IButtonDefault>>>(() => {
    if (!paywallId) {
      return []
    }
    return IAPService.getProductsFromPaywallId(paywallId)
      .map((product, index) => {
        const preparedConfig = cloneDeep({
          ...(config?.IAPBlock?.defaultButton ?? {}),
          ...(config?.IAPBlock?.customButtons?.[index] ?? {}),
        }) as IButton
        if (index !== 0 && preparedConfig.border?.width) {
          preparedConfig.border.width /= 2
        }
        return {
          element: preparedConfig,
          default: DefaultConfigurationProvider.getIAPButtonConfig(config, product, index),
        }
      })
  }, [config, paywallId])

  const purchaseButtonConfig = useMemo<IComponentConfig<IButton, IButtonDefault>>(() => ({
    element: config?.purchaseButton,
    default: DefaultConfigurationProvider.getPurchaseButtonConfig(config),
  }), [config])

  const privacyLabelConfig = useMemo<IComponentConfig<ILabel, ILabel>>(() => ({
    element: config?.privacy,
    default: DefaultConfigurationProvider.getPrivacyLabelConfig(config),
  }), [config])

  const termsLabelConfig = useMemo<IComponentConfig<ILabel, ILabel>>(() => ({
    element: config?.terms,
    default: DefaultConfigurationProvider.getTermsLabelConfig(config),
  }), [config])

  const iapButtonConfigPath = useCallback((index: number) => (
    `${ElementName.iapBlock}.${
      config?.[ElementName.iapBlock]?.[IAPBlockKey.CUSTOM_BUTTONS]?.[index]
        ? `${IAPBlockKey.CUSTOM_BUTTONS}.${index}`
        : `${IAPBlockKey.DEFAULT_BUTTON}`
    }`
  ), [config])

  return (
    <ACScreenView
      config={screenConfig?.element}
      defaultConfig={screenConfig?.default}
      configPath={`${ElementName.screen}`}
    >
      <RelativeBox>
        <MainImageView>
          <ACImage
            config={mainImageConfig?.element}
            defaultConfig={mainImageConfig?.default}
            configPath={`${ElementName.mainImage}`}
            cover
          />
        </MainImageView>
        <CloseIconView>
          <ACImage
            config={closeIconConfig?.element}
            defaultConfig={closeIconConfig?.default}
            configPath={`${ElementName.close}`}
          />
        </CloseIconView>
        <RestoreLabelView>
          <ACLabel
            config={restoreLabelConfig?.element}
            defaultConfig={restoreLabelConfig?.default}
            configPath={`${ElementName.restore}`}
          />
        </RestoreLabelView>
      </RelativeBox>
      { config?.title?.isEnabled !== false && (
        <RelativeBox>
          <TitleLabelView>
            <ACLabel
              config={titleLabelConfig?.element}
              defaultConfig={titleLabelConfig?.default}
              configPath={`${ElementName.title}`}
            />
          </TitleLabelView>
        </RelativeBox>
      )}
      <RelativeBox>
        <FeaturesView>
          { featuresConfig?.map((featureConfig: any, index: number) => (
            <FeatureItemView key={index}>
              <ACFeatureItem
                config={featureConfig.element}
                defaultConfig={featureConfig.default}
                configPath={`${ElementName.features}.${FeaturesKey.ELEMENTS}.${index}`}
              />
            </FeatureItemView>
          ))}
        </FeaturesView>
      </RelativeBox>
      <FlexSpacer />
      <RelativeBox>
        <IAPBlockView>
          { iapButtonsConfig?.map((iapButtonConfig, index) => (
            <IAPButtonView key={index}>
              <ACSelectableButton
                config={iapButtonConfig.element}
                defaultConfig={iapButtonConfig.default}
                configPath={iapButtonConfigPath(index)}
              />
            </IAPButtonView>
          ))}
        </IAPBlockView>
      </RelativeBox>
      <RelativeBox>
        <PurchaseButtonView>
          <ACDefaultButton
            config={purchaseButtonConfig?.element}
            defaultConfig={purchaseButtonConfig?.default}
            configPath={`${ElementName.purchaseButton}`}
          />
        </PurchaseButtonView>
      </RelativeBox>
      <RelativeBox>
        <LinksView>
          <ACLabel
            config={privacyLabelConfig?.element}
            defaultConfig={privacyLabelConfig?.default}
            configPath={`${ElementName.privacy}`}
          />
          <ACLabel
            config={termsLabelConfig?.element}
            defaultConfig={termsLabelConfig?.default}
            configPath={`${ElementName.terms}`}
          />
        </LinksView>
      </RelativeBox>
    </ACScreenView>
  )
}

export default SeveralOptionsWithFeatures
