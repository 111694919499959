import { ElementName } from './enums/Elements/ElementName'
import { ElementType } from './enums/StyleConfigAttributesTypes/ElementType'

const elementNameTypeMap = {
  [ElementName.theme]: ElementType.theme,
  [ElementName.screen]: ElementType.screen,
  [ElementName.mainImage]: ElementType.image,
  [ElementName.restore]: ElementType.label,
  [ElementName.close]: ElementType.image,
  [ElementName.title]: ElementType.label,
  [ElementName.features]: ElementType.features,
  [ElementName.feature]: ElementType.feature,
  [ElementName.feedbacks]: ElementType.label, // TODO: add feedbacks
  [ElementName.paymentInfo]: ElementType.label,
  [ElementName.iapBlock]: ElementType.iapBlock,
  [ElementName.purchaseButton]: ElementType.button,
  [ElementName.privacy]: ElementType.label,
  [ElementName.terms]: ElementType.label,
}

export default elementNameTypeMap
