import { IAppConfigItem, IState } from './types'
import { AppConfigKeys } from '../../../../models/enums/OtherConfigsKeys'
import { AppConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys'

export const DEFAULT_CONFIG_DATA: IAppConfigItem = {
  [AppConfigKeys.IMAGES]: {},
}

const initialState: IState = {
  currentConfigKey: AppConfigKey.DEFAULT,
  configs: [
    {
      key: AppConfigKey.DEFAULT,
      data: DEFAULT_CONFIG_DATA,
    },
  ],
}

export default initialState
