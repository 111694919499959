export const camelCaseToCapitalizesText = (text: string) => Array.from(text)
  .reduce(
    (acc, char, index) => {
      if (index === 0) {
        return acc + char.toUpperCase()
      }
      if (String(+char) === char) {
        return acc + char
      }
      if (char.toUpperCase() === char) {
        return `${acc} ${char.toUpperCase()}`
      }
      return acc + char
    },
    '',
  )
