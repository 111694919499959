import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './initialState'
import { IState } from './types'

export const getCurrentApplicationName = (
  state: IState,
): string | null => state.name

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => ({
      name: action.payload,
    }),
  },
})

export const {
  setName,
} = applicationSlice.actions

export default applicationSlice.reducer
