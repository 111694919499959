import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import iphoneX from '../../../assets/images/devicesMockups/iphoneX.png'
import PaywallScreen from './PaywallScreens'
import { StyledSectionInnerShadow } from '../../../styled'
import {
  StyledSection,
  StyledDeviceWrapper,
  StyledDeviceMockup,
  StyledScreenWrapper,
} from './styled'

const PaywallPreviewPanel: React.FC = (): ReactElement => (
  <StyledSection>
    <Grid
      container
      spacing={0}
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <StyledDeviceWrapper height="846px" width="422px">
          <StyledDeviceMockup src={iphoneX} />
          <StyledScreenWrapper>
            <PaywallScreen />
          </StyledScreenWrapper>
        </StyledDeviceWrapper>
      </Grid>
    </Grid>
    <StyledSectionInnerShadow />
  </StyledSection>
)

export default PaywallPreviewPanel
