import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './initialState'
import {
  ICreateDefLinkConditionActionPayload,
  ICreateConfigActionPayload,
  IUpdateDefLinkConditionActionPayload,
  IUpdateConfigActionPayload,
} from './types'
import {
  setCurrentDefLinkConditionKeyReducer,
  createConfigReducer,
  createDefLinkConditionReducer,
  deleteDefLinkConditionReducer,
  updateConfigReducer,
  updateDefLinkConditionReducer,
  saveStateReducer,
  loadStateReducer,
} from './reducers'

export const defLinksConditionsSlice = createSlice({
  name: 'defLinksConditions',
  initialState,
  reducers: {
    setCurrentDefLinkConditionKey: (
      state,
      action: PayloadAction<string>,
    ) => setCurrentDefLinkConditionKeyReducer(state, action),
    createConfig: (
      state,
      action: PayloadAction<ICreateConfigActionPayload>,
    ) => createConfigReducer(state, action.payload),
    createDefLinkCondition: (
      state,
      action: PayloadAction<ICreateDefLinkConditionActionPayload>,
    ) => createDefLinkConditionReducer(state, action.payload),
    updateDefLinkCondition: (
      state,
      action: PayloadAction<IUpdateDefLinkConditionActionPayload>,
    ) => updateDefLinkConditionReducer(state, action),
    updateConfig: (
      state,
      action: PayloadAction<IUpdateConfigActionPayload>,
    ) => updateConfigReducer(state, action),
    deleteDefLinkCondition: (
      state,
      action: PayloadAction<string>,
    ) => deleteDefLinkConditionReducer(state, action.payload),
    saveState: (
      state,
    ) => saveStateReducer(state),
    loadState: (
      state,
    ) => loadStateReducer(state),
  },
})

export const {
  setCurrentDefLinkConditionKey,
  createDefLinkCondition,
  createConfig,
  updateDefLinkCondition,
  updateConfig,
  deleteDefLinkCondition,
  saveState,
  loadState,
} = defLinksConditionsSlice.actions

export default defLinksConditionsSlice.reducer
