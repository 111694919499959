import React, { ReactElement, useCallback, MouseEvent } from 'react'
import { IIconProps } from './types'
import {
  StyledIconWrapper,
  StyledIcon,
} from './styled'

const Icon: React.FC<IIconProps> = (
  {
    style,
    className,
    icon,
    size,
    padding,
    disabled = false,
    onClick,
  },
): ReactElement => {
  const onLocalClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onClick?.()
  }, [onClick])

  return (
    <StyledIconWrapper
      onClick={(event) => !disabled && onLocalClick(event)}
      style={style}
      className={className}
      theme={{
        size,
        padding,
        clickable: !!onClick,
        disabled,
      }}
    >
      <StyledIcon src={icon} />
    </StyledIconWrapper>
  )
}

export default Icon
