import { Event } from './events'
import { IEventBus, Registry, Subscriber } from './types'

export class EventBus implements IEventBus {
  private static instance?: EventBus = undefined

  private readonly subscribers: Subscriber

  private static nextId = 0

  constructor() {
    this.subscribers = {}
  }

  public dispatch<T>(event: Event, arg?: T): void {
    const subscriber = this.subscribers[event]

    if (subscriber === undefined) {
      return
    }

    Object.keys(subscriber).forEach((key) => subscriber[key](arg))
  }

  public register(event: Event, callback: Function): Registry {
    const id = EventBus.getNextId()
    if (!this.subscribers[event]) this.subscribers[event] = {}

    this.subscribers[event][id] = callback

    return {
      unregister: () => {
        delete this.subscribers[event][id]
        if (Object.keys(this.subscribers[event]).length === 0) delete this.subscribers[event]
      },
    }
  }

  private static getNextId(): number {
    EventBus.nextId += 1
    return EventBus.nextId
  }

  public static get shared(): EventBus {
    if (this.instance === undefined) {
      this.instance = new EventBus()
    }
    return this.instance
  }
}
