import { PayloadAction } from '@reduxjs/toolkit'
import {
  DefLinksConfigKeys as DefLinksConfigKeysType,
} from '../../../../models/enums/OtherConfigsKeys/keys/DefLinksConfigKeys'
import {
  ICreateConfigActionPayload,
  ICreateDefLinkConfigActionPayload,
  IState,
  IUpdateConfigActionPayload,
  IUpdateDefLinkConfigActionPayload,
} from './types'
import { StoreError } from '../../../../models/enums/Errors'
import { DEFAULT_CONFIG_DATA } from './initialState'
import { getItem, storeItem } from '../../../../services/LocalStorage'
import { ConfigName } from '../../../../models/enums/ConfigName'
import { getDefLinkConfigByKey } from './getters'

export const saveStateReducer = (state: IState) => {
  storeItem(ConfigName.DEF_LINKS_CONFIG, state)
}

export const loadStateReducer = (state: IState) => {
  const storedState = getItem(ConfigName.DEF_LINKS_CONFIG)
  if (storedState !== null) {
    return storedState as IState
  }
  return state
}

export const setCurrentDefLinkConfigKeyReducer = (
  state: IState,
  action: PayloadAction<DefLinksConfigKeysType | string>,
) => {
  state.currentConfigKeys.defLinkConfig = action.payload

  saveStateReducer(state)

  return state
}
export const createConfigReducer = (state: IState, action: PayloadAction<ICreateConfigActionPayload>) => {
  state.config.data = action.payload.data

  saveStateReducer(state)

  return state
}

export const createDefLinkConfigReducer = (
  state: IState,
  payload: ICreateDefLinkConfigActionPayload,
) => {
  if (getDefLinkConfigByKey(state, payload.key) !== undefined) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }
  state.config.data[payload.key] = payload.data ?? DEFAULT_CONFIG_DATA

  saveStateReducer(state)

  return state
}

export const updateConfigReducer = (state: IState, action: PayloadAction<IUpdateConfigActionPayload>) => {
  state.config.data = action.payload.data

  saveStateReducer(state)

  return state
}

export const updateDefLinkConfigReducer = (
  state: IState,
  action: PayloadAction<IUpdateDefLinkConfigActionPayload>,
) => {
  if (getDefLinkConfigByKey(state, action.payload.key) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.config.data[action.payload.key] = action.payload.data

  saveStateReducer(state)

  return state
}

export const deleteDefLinkConfigReducer = (state: IState, payload: DefLinksConfigKeysType | string) => {
  if (getDefLinkConfigByKey(state, payload) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  delete state.config.data[payload]

  saveStateReducer(state)

  return state
}
