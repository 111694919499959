import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './initialState'
import {
  DefLinksConfigKeys as DefLinksConfigKeysType,
} from '../../../../models/enums/OtherConfigsKeys/types'
import {
  ICreateDefLinkConfigActionPayload,
  ICreateConfigActionPayload,
  IUpdateDefLinkConfigActionPayload,
  IUpdateConfigActionPayload,
} from './types'
import {
  setCurrentDefLinkConfigKeyReducer,
  createDefLinkConfigReducer,
  createConfigReducer,
  updateDefLinkConfigReducer,
  updateConfigReducer,
  deleteDefLinkConfigReducer,
  saveStateReducer,
  loadStateReducer,
} from './reducers'

export const defLinksConfigSlice = createSlice({
  name: 'defLinksConfig',
  initialState,
  reducers: {
    setCurrentDefLinkConfigKey: (
      state,
      action: PayloadAction<DefLinksConfigKeysType | string>,
    ) => setCurrentDefLinkConfigKeyReducer(state, action),
    createConfig: (
      state,
      action: PayloadAction<ICreateConfigActionPayload>,
    ) => createConfigReducer(state, action),
    createDefLinkConfig: (
      state,
      action: PayloadAction<ICreateDefLinkConfigActionPayload>,
    ) => createDefLinkConfigReducer(state, action.payload),
    updateConfig: (
      state,
      action: PayloadAction<IUpdateConfigActionPayload>,
    ) => updateConfigReducer(state, action),
    updateDefLinkConfig: (
      state,
      action: PayloadAction<IUpdateDefLinkConfigActionPayload>,
    ) => updateDefLinkConfigReducer(state, action),
    deleteDefLinkConfig: (
      state,
      action: PayloadAction<DefLinksConfigKeysType | string>,
    ) => deleteDefLinkConfigReducer(state, action.payload),
    saveState: (
      state,
    ) => saveStateReducer(state),
    loadState: (
      state,
    ) => loadStateReducer(state),
  },
})

export const {
  setCurrentDefLinkConfigKey,
  createDefLinkConfig,
  createConfig,
  updateDefLinkConfig,
  updateConfig,
  deleteDefLinkConfig,
  saveState,
  loadState,
} = defLinksConfigSlice.actions

export default defLinksConfigSlice.reducer
