import { PayloadAction } from '@reduxjs/toolkit'
import {
  ICreateConfigActionPayload,
  ICreateDefLinkConditionActionPayload, IState, IUpdateConfigActionPayload,
  IUpdateDefLinkConditionActionPayload,
} from './types'
import { StoreError } from '../../../../models/enums/Errors'
import { DEFAULT_CONFIG_DATA } from './initialState'
import { getItem, storeItem } from '../../../../services/LocalStorage'
import { ConfigName } from '../../../../models/enums/ConfigName'
import { getDefLinkConditionByKey } from './getters'

export const saveStateReducer = (state: IState) => {
  storeItem(ConfigName.DEF_LINKS_CONDITIONS, state)
}

export const loadStateReducer = (state: IState) => {
  const storedState = getItem(ConfigName.DEF_LINKS_CONDITIONS)
  if (storedState !== null) {
    return storedState as IState
  }
  return state
}

export const setCurrentDefLinkConditionKeyReducer = (state: IState, action: PayloadAction<string>) => {
  state.currentConfigKeys.defLinkCondition = action.payload

  saveStateReducer(state)

  return state
}

export const createConfigReducer = (state: IState, payload: ICreateConfigActionPayload) => {
  state.config.data = payload.data

  saveStateReducer(state)

  return state
}

export const createDefLinkConditionReducer = (
  state: IState,
  payload: ICreateDefLinkConditionActionPayload,
) => {
  if (getDefLinkConditionByKey(state, payload.key) !== undefined) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }
  state.config.data[payload.key] = payload.data ?? DEFAULT_CONFIG_DATA

  saveStateReducer(state)

  return state
}

export const updateDefLinkConditionReducer = (
  state: IState,
  action: PayloadAction<IUpdateDefLinkConditionActionPayload>,
) => {
  if (getDefLinkConditionByKey(state, action.payload.key) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.config.data[action.payload.key] = action.payload.data

  saveStateReducer(state)

  return state
}

export const updateConfigReducer = (state: IState, action: PayloadAction<IUpdateConfigActionPayload>) => {
  state.config.data = action.payload.data

  saveStateReducer(state)

  return state
}

export const deleteDefLinkConditionReducer = (state: IState, payload: string) => {
  if (getDefLinkConditionByKey(state, payload) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  delete state.config.data[payload]

  saveStateReducer(state)

  return state
}
