import React, {
  ReactElement, useMemo, useState, Suspense,
} from 'react'
import { StyledSectionInnerShadow } from '../../../styled'
import {
  StyledSection,
  StyledViewWrapper,
} from './styled'
import Navigation from './_components/Navigation'
import { ViewKey, Views } from './data'

const PaywallSettingsPanel: React.FC = (): ReactElement => {
  const [viewKey, setViewKey] = useState<ViewKey | null>(null)

  const CurrenView = useMemo<React.ElementType>(
    () => (Views[viewKey ?? ViewKey.GENERAL]),
    [viewKey],
  )

  return (
    <StyledSection>
      <Navigation onSelect={(item) => setViewKey(item.key)} />
      <StyledViewWrapper>
        <Suspense fallback={<div />}>
          { viewKey && <CurrenView /> }
        </Suspense>
      </StyledViewWrapper>
      <StyledSectionInnerShadow />
    </StyledSection>
  )
}
export default PaywallSettingsPanel
