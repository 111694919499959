import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './initialState'
import {
  IState,
  ICreateConfigActionPayload,
  IUpdateConfigActionPayload,
} from './types'
import { AppConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/types'
import {
  setCurrentConfigKeyReducer,
  createConfigReducer,
  updateConfigReducer,
  deleteConfigReducer,
  saveStateReducer,
  loadStateReducer,
} from './reducers'
import {
  updateConfig as updateDefLinksConfig,
} from '../defLinksConfig'
import {
  IDefLinkConfig,
  IUpdateConfigActionPayload as IUpdateDefLinksConfigActionPayload,
} from '../defLinksConfig/types'
import { getConfigs } from './getters'
import { DefLinkConfigKeys } from '../../../../models/enums/OtherConfigsKeys'

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setCurrentConfigKey: (
      state,
      action: PayloadAction<AppConfigKey | string>,
    ) => setCurrentConfigKeyReducer(state, action),
    createConfig: (
      state,
      action: PayloadAction<ICreateConfigActionPayload>,
    ) => createConfigReducer(state, action.payload),
    updateConfig: (
      state,
      action: PayloadAction<IUpdateConfigActionPayload>,
    ) => updateConfigReducer(state, action),
    deleteConfig: (
      state,
      action: PayloadAction<AppConfigKey | string>,
    ) => deleteConfigReducer(state, action.payload),
    saveState: (
      state,
    ) => saveStateReducer(state),
    loadState: (
      state,
    ) => loadStateReducer(state),
  },
  extraReducers: {
    [updateDefLinksConfig.type]: (
      state: IState,
      action: PayloadAction<IUpdateDefLinksConfigActionPayload>,
    ) => {
      // TODO: add to other reducers + refactoring
      const newConfigData = action.payload.data
      const appConfigs = getConfigs(state)
      appConfigs.forEach((appConfig) => {
        const newItemExist = !!Object.values(newConfigData)
          .find((
            defLinkConfig: IDefLinkConfig | undefined, // TODO: check what is undefined here and fix
          ) => defLinkConfig && defLinkConfig?.[DefLinkConfigKeys.APP_CONFIG_KEY] === appConfig.key)
        if (!newItemExist) {
          deleteConfigReducer(state, appConfig.key)
        }
      })
      Object.values(newConfigData).forEach((defLinkConfig: IDefLinkConfig | undefined) => {
        const newItemExist = !!appConfigs
          .find(
            (appConfig) => defLinkConfig && defLinkConfig?.[DefLinkConfigKeys.APP_CONFIG_KEY] === appConfig.key,
          )
        if (defLinkConfig && !newItemExist) {
          createConfigReducer(state, {
            key: defLinkConfig[DefLinkConfigKeys.APP_CONFIG_KEY],
          })
        }
      })
    },
  },
})

export const {
  setCurrentConfigKey,
  createConfig,
  updateConfig,
  deleteConfig,
  saveState,
  loadState,
} = appConfigSlice.actions

export default appConfigSlice.reducer
