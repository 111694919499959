export class IAPService {
  public static getProductsFromPaywallId(paywallId: string) {
    const paywallStringParts = paywallId.split('_')
    const productsNumber = +paywallStringParts[0][3]
    const products = []
    for (let i = 1; i < productsNumber + 1; i++) {
      if (paywallStringParts[i]) {
        products.push(IAPService.parseProduct(paywallStringParts[i]))
      }
    }
    return products
  }

  private static parseProduct(productShortName: string) {
    const localizedPeriod = IAPService.getLocalizedPeriod(productShortName[1], +productShortName[0])
    const price = parseInt(productShortName.slice(2), 10)
    const introductoryStartIndex = `${price}`.length + 2
    const introductoryPart = productShortName.slice(introductoryStartIndex)
    return {
      localizedPeriod,
      price: `$${price - 0.01}`,
      introductoryOffer: introductoryPart.length > 0
        ? {
          localizedPeriod: IAPService.getLocalizedPeriod(
            introductoryPart[0] === 't' ? 'd' : introductoryPart[2],
            +introductoryPart[1],
          ),
          price: introductoryPart[0] === 't' ? null : '$XX',
        }
        : null,
    }
  }

  private static getLocalizedPeriod(periodShortName: string, numberOfPeriods: number) {
    switch (periodShortName) {
      case 'd':
        return `${numberOfPeriods} ${numberOfPeriods !== 1 ? 'days' : 'day'}`
      case 'w':
        return `${numberOfPeriods} ${numberOfPeriods !== 1 ? 'weeks' : 'week'}`
      case 'm':
        return `${numberOfPeriods} ${numberOfPeriods !== 1 ? 'months' : 'month'}`
      case 'y':
        return `${numberOfPeriods} ${numberOfPeriods !== 1 ? 'years' : 'year'}`
      default:
        console.log('getLocalizedPeriod error')
        return ''
    }
  }
}
