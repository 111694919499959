import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Shadow } from '../../theme'
import { IStyledProps } from '../../models/types/helper/styledComponents'
import { IMenuTheme } from './types'

export const StyledMenu = styled(Box)<IStyledProps<IMenuTheme>>`
  background-color: ${Color.opposite};
  position: absolute;
  bottom: 0;
  left: ${({ theme }) => (theme.position === 'left' ? 0 : 'auto')};
  right: ${({ theme }) => (theme.position === 'right' ? 0 : 'auto')};
  transform: translateY(${({ theme }) => (theme.topMargin ? `calc(100% + ${theme.topMargin}px)` : '100%')});
  width: fit-content;
  box-shadow: ${Shadow.card.sm};
  z-index: 2000;
`

export const StyledMenuWrapper = styled(Box)`
  position: relative;
`
