import { INavigationItem } from './types'
import { ViewKey } from '../../data'

export const navigationItems: Array<INavigationItem> = [
  {
    title: 'General',
    key: ViewKey.GENERAL,
  },
  {
    title: 'Translations',
    key: ViewKey.TRANSLATIONS,
  },
  {
    title: 'Mockup',
    key: ViewKey.MOCKUP,
  },
  {
    title: 'History',
    key: ViewKey.HISTORY,
  },
]
