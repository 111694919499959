import {
  ILifeCycleHook, IMonetizationItem, IState, ISubscriptionState,
} from './types'
import { LifeCycleKeys, MonetizationKeys, PaywallConfig } from '../../../../models/enums/OtherConfigsKeys'
import { MonetizationKey } from '../../../../models/enums/DefaultFirebaseRCKeys'
import { SubscriptionState } from '../../../../models/enums/OtherConfigsKeys/keys/SubscriptionState'
import { LifeCycleHook } from '../../../../models/enums/OtherConfigsKeys/keys/LifeCycleHook'
import { ScreenStyleConfigKeys } from '../../../../models/enums/ScreenStyleConfigKeys'
import { ScreenStyleConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/ScreenStyleConfigKey'

export const DEFAULT_SUBSCRIPTION_STATE: ISubscriptionState = {
  [PaywallConfig.IS_ENABLED]: true,
  [PaywallConfig.SKIPPABLE]: true,
  [PaywallConfig.CUSTOM_FREE_TRIAL]: null,
  [PaywallConfig.PAYWALL_ID]: 'opt2_1m30_1y100t3', // TODO: null by default + required
  [PaywallConfig.SCREEN_NAME]: ScreenStyleConfigKeys.SeveralOptionsWithFeatures,
  [PaywallConfig.SCREEN_STYLE_CONFIG_KEY]: ScreenStyleConfigKey.DEFAULT,
}

export const DEFAULT_HOOK: ILifeCycleHook = {
  [LifeCycleKeys.IS_ENABLED]: true,
  [LifeCycleKeys.SKIPPABLE]: true,
  [LifeCycleKeys.CUSTOM_FREE_TRIAL]: null,
  [LifeCycleKeys.SUBSCRIPTION_CONFIGS]: {
    [SubscriptionState.DEFAULT]: DEFAULT_SUBSCRIPTION_STATE,
  },
}

export const DEFAULT_CONFIG_DATA: IMonetizationItem = {
  [MonetizationKeys.SKIPPABLE]: true,
  [MonetizationKeys.CUSTOM_FREE_TRIAL]: null,
  [MonetizationKeys.LIFE_CYCLE_KEY]: {
    [LifeCycleHook.DEFAULT]: DEFAULT_HOOK,
  },
}

const initialState: IState = {
  currentConfigKeys: {
    monetization: MonetizationKey.DEFAULT,
    hook: LifeCycleHook.DEFAULT,
    subscriptionState: SubscriptionState.DEFAULT,
  },
  configs: [
    {
      key: MonetizationKey.DEFAULT,
      data: DEFAULT_CONFIG_DATA,
    },
  ],
}

export default initialState
