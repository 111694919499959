export enum ElementType {
  theme = 'theme',
  screen = 'screen',
  image = 'image',
  label = 'label',
  features = 'features',
  feature = 'feature',
  iapBlock = 'IAPBlock',
  button = 'button',
  shadow = 'shadow',
}
