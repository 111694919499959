export enum ElementName {
  theme = 'theme',
  screen = 'screen',
  mainImage = 'mainImage',
  restore = 'restore',
  close = 'close',
  title = 'title',
  features = 'features',
  feature = 'feature',
  feedbacks = 'feedbacks',
  iapBlock = 'IAPBlock',
  purchaseButton = 'purchaseButton',
  paymentInfo = 'paymentInfo',
  privacy = 'privacy',
  terms = 'terms',
}
