import { DefLinkConditionsType, IDefLinkCondition, IState } from './types'
import { DefLinksConditionsKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/DefLinksConditionsKey'

export const getConfig = (
  state: IState,
): DefLinkConditionsType => state.config.data

export const getConfigKey = (
  state: IState,
): DefLinksConditionsKey.PERMANENT => state.currentConfigKeys.defLinksCondition

export const getDefLinkConditions = (
  state: IState,
) => Object.entries(state.config.data)
  .reduce((acc, [conditionKey, conditionValue]) => {
    acc.push({
      key: conditionKey,
      data: conditionValue as IDefLinkCondition,
    })
    return acc
  }, [] as Array<{ key: string, data: IDefLinkCondition }>)

export const getDefLinkConditionByKey = (
  state: IState,
  key: string,
): IDefLinkCondition | undefined => Object.entries(state.config.data)
  .find(([conditionKey]) => conditionKey === key)?.[1]

export const getCurrentDefLinkConditionData = (
  state: IState,
): IDefLinkCondition | undefined => Object.entries(state.config.data)
  .find(
    ([conditionKey]) => conditionKey === state.currentConfigKeys.defLinkCondition,
  )?.[1]

export const getCurrentDefLinkConditionKey = (
  state: IState,
): string | null => state.currentConfigKeys.defLinkCondition
