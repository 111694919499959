import React, { ReactElement } from 'react'
import { IDefaultElementPlateProps } from './types'
import ConfigurationFieldsProvider
  from '../../../../../../../components/PaywallConfigurationFields/ConfigurationFieldsProvider'
import {
  StyledDefaultElementPlate,
  StyledDefaultElementColumn,
  StyledDefaultElementColumnWrapper,
} from './styled'

const DefaultElementPlate: React.FC<IDefaultElementPlateProps> = (
  {
    plateElements,
    config,
    theme,
    onChange,
  },
): ReactElement => {
  const localOnChange = (configValue: any, fieldName: string) => onChange({
    ...config,
    [fieldName]: configValue,
  })

  return (
    <StyledDefaultElementPlate>
      <StyledDefaultElementColumnWrapper>
        <StyledDefaultElementColumn>
          {Object.entries(plateElements).map(
            ([fieldName, fieldType]) => (
              <ConfigurationFieldsProvider
                key={fieldName}
                fieldType={fieldType.styleAttributeType}
                elementType={fieldType.elementType}
                fieldName={fieldName}
                configValue={config[fieldName]}
                theme={theme}
                onChange={(configValue) => localOnChange(configValue, fieldName)}
              />
            ),
          )}
        </StyledDefaultElementColumn>
      </StyledDefaultElementColumnWrapper>
    </StyledDefaultElementPlate>
  )
}

export default DefaultElementPlate
