import { PayloadAction } from '@reduxjs/toolkit'
import { MonetizationKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/MonetizationKey'
import { LifeCycleHook } from '../../../../models/enums/OtherConfigsKeys/keys/LifeCycleHook'
import { SubscriptionState } from '../../../../models/enums/OtherConfigsKeys/keys/SubscriptionState'
import {
  ICreateConfigActionPayload,
  ICreateHookConfigActionPayload,
  ICreateSubscriptionConfigActionPayload,
  IDeleteHookConfigActionPayload,
  IDeleteSubscriptionConfigActionPayload,
  IState,
  IUpdateConfigActionPayload,
  IUpdateHookConfigActionPayload,
  IUpdateSubscriptionConfigActionPayload,
} from './types'
import { StoreError } from '../../../../models/enums/Errors'
import { DEFAULT_CONFIG_DATA } from './initialState'
import { MonetizationKeys } from '../../../../models/enums/OtherConfigsKeys'
import { getItem, storeItem } from '../../../../services/LocalStorage'
import { ConfigName } from '../../../../models/enums/ConfigName'
import {
  getConfigByKey,
  getConfigIndexByKey,
  getHookConfigByKey,
  getSubscriptionConfigByKey,
} from './getters'
import { LifeCycleKeys } from '../../../../models/enums/OtherConfigsKeys/keys/LifeCycleKeys'

export const saveStateReducer = (state: IState) => {
  storeItem(ConfigName.MONETIZATION_CONFIG, state)
}

export const loadStateReducer = (state: IState) => {
  const storedState = getItem(ConfigName.MONETIZATION_CONFIG)
  if (storedState !== null) {
    return storedState as IState
  }
  return state
}

export const setCurrentConfigKeyReducer = (state: IState, payload: MonetizationKey | string) => {
  state.currentConfigKeys.monetization = payload

  saveStateReducer(state)

  return state
}

export const setCurrentHookReducer = (state: IState, action: PayloadAction<LifeCycleHook | string>) => {
  state.currentConfigKeys.hook = action.payload

  saveStateReducer(state)

  return state
}

export const setCurrentSubscriptionStateReducer = (state: IState, action: PayloadAction<SubscriptionState>) => {
  state.currentConfigKeys.subscriptionState = action.payload

  saveStateReducer(state)

  return state
}

// Meta: createConfig

export const createConfigReducer = (state: IState, payload: ICreateConfigActionPayload) => {
  if (getConfigByKey(state, payload.key) !== undefined) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }
  state.configs.push({
    key: payload.key,
    data: payload.data ?? DEFAULT_CONFIG_DATA,
  })

  saveStateReducer(state)

  return state
}

export const createHookConfigReducer = (state: IState, action: PayloadAction<ICreateHookConfigActionPayload>) => {
  const index = getConfigIndexByKey(state, action.payload.monetizationKey)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (getHookConfigByKey(state, action.payload.key, action.payload.monetizationKey) !== undefined) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }
  state.configs[index].data[MonetizationKeys.LIFE_CYCLE_KEY][action.payload.key] = action.payload.data

  saveStateReducer(state)

  return state
}

export const createSubscriptionConfigReducer = (
  state: IState,
  action: PayloadAction<ICreateSubscriptionConfigActionPayload>,
) => {
  const index = getConfigIndexByKey(state, action.payload.monetizationKey)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (getHookConfigByKey(state, action.payload.key, action.payload.monetizationKey) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (
    getSubscriptionConfigByKey(
      state,
      action.payload.key,
      action.payload.hook,
      action.payload.monetizationKey,
    ) !== undefined
  ) {
    throw new Error(StoreError.ITEM_ALREADY_EXIST)
  }

  const { hook, key } = action.payload
  const LC_KEY = MonetizationKeys.LIFE_CYCLE_KEY
  const SUBS_KEY = LifeCycleKeys.SUBSCRIPTION_CONFIGS
  // @ts-ignore TODO: Wtf?
  state.configs[index].data[LC_KEY][hook][SUBS_KEY][key] = action.payload.data

  saveStateReducer(state)

  return state
}

// Meta: updateConfig

export const updateConfigReducer = (state: IState, action: PayloadAction<IUpdateConfigActionPayload>) => {
  const index = getConfigIndexByKey(state, action.payload.key)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs.splice(index, 1, {
    key: action.payload.key,
    data: action.payload.data,
  })

  saveStateReducer(state)

  return state
}

export const updateHookConfigReducer = (state: IState, action: PayloadAction<IUpdateHookConfigActionPayload>) => {
  const index = getConfigIndexByKey(state, action.payload.monetizationKey)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (getHookConfigByKey(state, action.payload.key, action.payload.monetizationKey) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs[index].data[MonetizationKeys.LIFE_CYCLE_KEY][action.payload.key] = action.payload.data

  saveStateReducer(state)

  return state
}

export const updateSubscriptionConfigReducer = (
  state: IState,
  action: PayloadAction<IUpdateSubscriptionConfigActionPayload>,
) => {
  const index = getConfigIndexByKey(state, action.payload.monetizationKey)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (getHookConfigByKey(state, action.payload.hook, action.payload.monetizationKey) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (
    getSubscriptionConfigByKey(
      state,
      action.payload.key,
      action.payload.hook,
      action.payload.monetizationKey,
    ) === undefined
  ) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }

  const { hook, key } = action.payload
  const LC_KEY = MonetizationKeys.LIFE_CYCLE_KEY
  const SUBS_KEY = LifeCycleKeys.SUBSCRIPTION_CONFIGS
  // @ts-ignore TODO: Wtf?
  state.configs[index].data[LC_KEY][hook][SUBS_KEY][key] = action.payload.data

  saveStateReducer(state)

  return state
}

export const deleteConfigReducer = (state: IState, payload: MonetizationKey | string) => {
  const index = getConfigIndexByKey(state, payload)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  state.configs.splice(index, 1)

  saveStateReducer(state)

  return state
}
export const deleteHookConfigReducer = (state: IState, action: PayloadAction<IDeleteHookConfigActionPayload>) => {
  const index = getConfigIndexByKey(state, action.payload.monetizationKey)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (getHookConfigByKey(state, action.payload.key, action.payload.monetizationKey) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  delete state.configs[index].data[MonetizationKeys.LIFE_CYCLE_KEY][action.payload.key]

  saveStateReducer(state)

  return state
}
export const deleteSubscriptionConfigReducer = (
  state: IState,
  action: PayloadAction<IDeleteSubscriptionConfigActionPayload>,
) => {
  const index = getConfigIndexByKey(state, action.payload.monetizationKey)
  if (index === -1) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (getHookConfigByKey(state, action.payload.key, action.payload.monetizationKey) === undefined) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }
  if (
    getSubscriptionConfigByKey(
      state,
      action.payload.key,
      action.payload.hook,
      action.payload.monetizationKey,
    ) === undefined
  ) {
    throw new Error(StoreError.ITEM_NOT_FOUND)
  }

  const { hook, key } = action.payload
  const LC_KEY = MonetizationKeys.LIFE_CYCLE_KEY
  const SUBS_KEY = LifeCycleKeys.SUBSCRIPTION_CONFIGS
  // @ts-ignore TODO: Wtf?
  delete state.configs[index].data[LC_KEY][hook][SUBS_KEY][key]

  saveStateReducer(state)

  return state
}
