import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { INavigationProps } from './types'
import {
  StyledNavigation,
  StyledNavigationItem,
  StyledNavigationItemWrapper,
} from './styled'
import {
  navigationItems,
} from './data'

const Navigation: React.FC<INavigationProps> = (
  {
    style,
    className,
    onSelect,
  },
): ReactElement => {
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0)

  const onNavigationItemClick = useCallback((index: number) => {
    setCurrentItemIndex(index)
  }, [])

  useEffect(() => {
    onSelect?.(navigationItems[currentItemIndex])
  }, [currentItemIndex, onSelect])

  return (
    <StyledNavigation style={style} className={className}>
      { navigationItems.map((item, index) => (
        <StyledNavigationItemWrapper
          key={item.title}
          onClick={() => onNavigationItemClick(index)}
          theme={{
            isCurrent: currentItemIndex === index,
          }}
        >
          <StyledNavigationItem>
            {item.title}
          </StyledNavigationItem>
        </StyledNavigationItemWrapper>
      ))}
    </StyledNavigation>
  )
}

export default Navigation
