import { IState } from './types'
import { DefLinkConfigKeys, DefLinksConfigKeys } from '../../../../models/enums/OtherConfigsKeys'
import { AppConfigKey, MonetizationKey } from '../../../../models/enums/DefaultFirebaseRCKeys'
import { DefLinksConfigKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/DefLinksConfigKey'

export const DEFAULT_CONFIG_DATA = {
  [DefLinkConfigKeys.MONETIZATION_CONFIG_KEY]: MonetizationKey.DEFAULT,
  [DefLinkConfigKeys.APP_CONFIG_KEY]: AppConfigKey.DEFAULT,
}

export const NON_ORGANIC_DEFAULT_CONFIG_DATA = {
  [DefLinkConfigKeys.MONETIZATION_CONFIG_KEY]: MonetizationKey.DEFAULT,
  [DefLinkConfigKeys.APP_CONFIG_KEY]: AppConfigKey.DEFAULT,
}

export const CONFIG_DEFAULT = {
  key: DefLinksConfigKey.PERMANENT,
  data: {
    [DefLinksConfigKeys.DEFAULT]: DEFAULT_CONFIG_DATA,
    [DefLinksConfigKeys.NON_ORGANIC_DEFAULT]: NON_ORGANIC_DEFAULT_CONFIG_DATA,
  },
}

const initialState: IState = {
  currentConfigKeys: {
    defLinksConfig: DefLinksConfigKey.PERMANENT,
    defLinkConfig: DefLinksConfigKeys.DEFAULT,
  },
  config: CONFIG_DEFAULT,
}

export default initialState
