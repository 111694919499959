export enum LifeCycleHook {
  DEFAULT = 'default',
  AFTER_DEFAULT = 'afterDefault',
  NEW_SESSION = 'newSession',
  AFTER_NEW_SESSION = 'afterNewSession',
  INTERNAL = 'internal',
  AFTER_INTERNAL = 'afterInternal',
  ONBOARDING = 'onboarding',
  AFTER_ONBOARDING = 'afterOnboarding',
  SETTINGS = 'settings',
  AFTER_SETTINGS = 'afterSettings',
}
