import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { IStyledProps } from '../../models/types/helper/styledComponents'
import { IIconTheme } from './types'
import { Unselectable } from '../../styled'
import { hexOpacity } from '../../utils/hex'
import { Color, Filter } from '../../theme'

export const StyledIcon = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`

export const StyledIconWrapper = styled(Box)<IStyledProps<IIconTheme>>`
  width: ${({ theme }) => theme.size ?? 20}px;
  min-width: ${({ theme }) => theme.size ?? 20}px;
  height: ${({ theme }) => theme.size ?? 20}px;
  min-height: ${({ theme }) => theme.size ?? 20}px;
  padding: ${({ theme }) => theme.padding ?? 0}px;
  box-sizing: content-box;
  cursor: ${({ theme }) => (theme.clickable && !theme.disabled ? 'pointer' : 'inherit')};
  filter: ${({ theme }) => (theme.disabled ? Filter.disabled : 'none')};
  &:hover {
    background-color: ${({ theme }) => (theme.clickable && !theme.disabled
    ? `${Color.text.first}${hexOpacity(0.1)}`
    : 'inherit')};
  }
  ${Unselectable};
`
