import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Shadow } from '../../../theme'

export const StyledSection = styled.section`
  background: ${Color.opposite};
  height: 100%;
  box-shadow: ${Shadow.card.lg};
  font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  position: relative;
`

export const StyledDeviceWrapper = styled(Box)`
  position: relative;
`

export const StyledDeviceMockup = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const StyledScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 22px 26px 24px 27px;
`
