import React, {
  ReactElement, useCallback, useState, Suspense,
} from 'react'
import { IElementProps } from './types'
import {
  StyledElementWrapper,
  StyledElementColumnWrapper,
  StyledElementColumn,
  StyledElementHeader,
  StyledIcon,
  StyledRemoveIcon,
} from './styled'
import ConfigurationFieldsProvider from '../ConfigurationFieldsProvider'
import { camelCaseToCapitalizesText } from '../../../utils/string'
import { PaywallElement } from '../../../models/paywallConfigurationFields'
import arrowDown from '../../../assets/images/icons/arrowDown.svg'
import deleteIcon from '../../../assets/images/icons/delete.svg'
import { Font } from '../../../theme'
import { filterObject } from '../../../utils/object'

const ACElement: React.FC<IElementProps> = (
  {
    fieldName,
    elementType,
    config,
    onChange,
    theme,
    onRemove,
  },
): ReactElement => {
  const [open, setOpen] = useState(false)

  const switchOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const localOnChange = (configValue: any, childFieldName: string) => {
    const newConfig = filterObject({
      ...(config ?? {}),
      [childFieldName]: configValue,
    })
    onChange(
      Object.values(newConfig).length === 0
        ? undefined
        : newConfig,
    )
  }

  return (
    <StyledElementWrapper>
      <StyledElementHeader onClick={switchOpen}>
        {camelCaseToCapitalizesText(fieldName)}
        <StyledIcon
          theme={{
            opened: open,
          }}
          onClick={switchOpen}
          icon={arrowDown}
          size={Font.md.sizeNumber}
        />
        { onRemove && (
          <StyledRemoveIcon
            onClick={onRemove}
            icon={deleteIcon}
            size={Font.md.sizeNumber}
          />
        )}
      </StyledElementHeader>
      { open && (
        <Suspense fallback={<div />}>
          <StyledElementColumnWrapper>
            <StyledElementColumn>
              {Object.entries(PaywallElement[elementType]).map(
                ([childFieldName, childFieldType]) => (
                  <ConfigurationFieldsProvider
                    key={childFieldName}
                    fieldType={childFieldType.styleAttributeType}
                    elementType={childFieldType.elementType}
                    fieldName={childFieldName}
                    configValue={config?.[childFieldName]}
                    theme={theme}
                    onChange={(configValue) => localOnChange(configValue, childFieldName)}
                  />
                ),
              )}
            </StyledElementColumn>
          </StyledElementColumnWrapper>
        </Suspense>
      )}
    </StyledElementWrapper>
  )
}

export default ACElement
