import React, {
  ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { ElementFullName } from '../../../models/enums/Elements/ElementFullName'
import { ElementName } from '../../../models/enums/Elements/ElementName'
import { IConfigElement } from './types'
import {
  StyledAccordionItem,
  StyledConfigElementsWrapper,
  StyledConfigNavigation,
  StyledSection,
  StyledHighlightTransition,
  StyledAccordionItemContentWrapper,
  StyledElementConfigurationPlateWrapper,
} from './styled'
import elementNameTypeMap from '../../../models/elementNameTypeMap'
import AccordionHeaderTextComponent from './_components/AccordionHeaderTextComponent'
import { StyledSectionInnerShadow } from '../../../styled'
import {
  ScreenStyleConfigProvider,
} from '../../../services/ConfigurationProviders'
import { IRefMethodsAccordionItem } from '../../../components/AccordionItem/types'
import { EventBus } from '../../../services/EventBus'
import { Event } from '../../../services/EventBus/events'
import ElementConfigurationPlate from './_components/ElementConfigurationPlate'
import { getScreenStyleConfigElements } from '../../../models/configs/screenStyleConfig'

let elementHoverClickSubscriber: {
  unregister: () => void
} | null = null

const PaywallConfigurationPanel: React.FC = (): ReactElement => {
  const config = useSelector(() => ScreenStyleConfigProvider.shared.currentData)
  const configName = useSelector(() => ScreenStyleConfigProvider.shared.current?.name)
  const configKey = useSelector(() => ScreenStyleConfigProvider.shared.currentKey)

  const [toggleHighlightIndex, setToggleHighlightIndex] = useState<number | null>(null)
  const [highlightFinishTimeout, setHighlightFinishTimeout] = useState<NodeJS.Timeout | null>(null)

  const configElements = useMemo(() => {
    if (!configName) {
      return []
    }
    return getScreenStyleConfigElements(configName)
      ?.reduce((acc: Array<IConfigElement>, key: ElementName) => {
        acc.push({
          key,
          name: ElementFullName[key],
          type: elementNameTypeMap[key],
          config: config[key] ?? {},
        })
        return acc
      }, [] as Array<IConfigElement>) ?? []
  }, [config, configName])

  const accordionItemRefs = useRef<Array<RefObject<IRefMethodsAccordionItem>>>(
    Array.from({ length: configElements.length }, () => React.createRef()),
  )

  useEffect(() => {
    elementHoverClickSubscriber = EventBus.shared.register(
      Event.paywallPreview_onElementHoverClick,
      (configPath: string) => {
        if (highlightFinishTimeout) {
          clearTimeout(highlightFinishTimeout)
        }

        const rootKey = configPath.split('.', 1)[0]
        const index = configElements.findIndex((configElement) => configElement.key === rootKey)

        accordionItemRefs.current[index]?.current?.open()

        setToggleHighlightIndex(null)
        setTimeout(() => {
          setToggleHighlightIndex(index)
          accordionItemRefs.current[index]?.current?.scrollIntoView()
        }, 100)

        setHighlightFinishTimeout(
          setTimeout(() => {
            setToggleHighlightIndex(null)
          }, 1000),
        )
      },
    )
    return () => {
      elementHoverClickSubscriber?.unregister()
    }
  }, [configElements, highlightFinishTimeout, toggleHighlightIndex])

  const onChange = useCallback((elementConfig: any, configElement: IConfigElement) => {
    const newConfig = {
      ...config,
      [configElement.key]: elementConfig,
    }
    ScreenStyleConfigProvider.shared.updateConfig({
      key: configKey,
      data: newConfig,
    })
  }, [config, configKey])

  return (
    <StyledSection>
      <StyledConfigNavigation />
      <StyledConfigElementsWrapper>
        { configElements.map((configElement, index) => (
          <StyledAccordionItem
            ref={accordionItemRefs.current[index]}
            key={configElement.name}
            HeaderTextComponent={(
              <AccordionHeaderTextComponent name={configElement.name} type={configElement.type} />
                )}
          >
            <StyledAccordionItemContentWrapper>
              <StyledHighlightTransition theme={{
                toggle: toggleHighlightIndex === index,
              }}
              >
                <StyledElementConfigurationPlateWrapper>
                  <ElementConfigurationPlate
                    config={configElement.config}
                    type={configElement.type}
                    theme={config.theme}
                    onChange={(elementConfig) => onChange(elementConfig, configElement)}
                  />
                </StyledElementConfigurationPlateWrapper>
              </StyledHighlightTransition>
            </StyledAccordionItemContentWrapper>
          </StyledAccordionItem>
        ))}
      </StyledConfigElementsWrapper>
      <StyledSectionInnerShadow />
    </StyledSection>
  )
}

export default PaywallConfigurationPanel
