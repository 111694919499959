import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { StyledFont } from '../../../../../../../styled'
import { Padding } from '../../../../../../../theme'

export const StyledThemePlate = styled(Box)`
  display: flex;
`

export const StyledThemeColumn = styled(Box)`
  width: min-content;
`

export const StyledThemeColumnWrapper = styled(Box)`
  width: 50%;
`

export const StyledThemeHeader = styled(Box)`
  ${StyledFont.smBold};
  margin-bottom: ${Padding.sm};
`
