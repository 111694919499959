import styled from '@emotion/styled'
import { Padding, Transition } from '../../../theme'
import { StyledFont } from '../../../styled'
import Icon from '../../Icon'
import { IStyledProps } from '../../../models/types/helper/styledComponents'
import { IIconTheme } from '../../AccordionItem/types'

export const StyledElementWrapper = styled.div`
`

export const StyledElementColumnWrapper = styled.div`
  width: 100%;
  margin-left: ${Padding.md};
`

export const StyledElementColumn = styled.div`
  width: min-content;
`

export const StyledElementHeader = styled.div`
  ${StyledFont.smBold};
  margin-bottom: ${Padding.sm};
  margin-top: ${Padding.sm};
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: italic;
`

export const StyledIcon = styled(Icon)<IStyledProps<IIconTheme>>`
  margin-left: calc(${Padding.xxs} / 2);
  transform: ${({ theme }) => (theme.opened ? 'rotate(180deg)' : 'none')};
  transition: ${Transition.default};
`

export const StyledRemoveIcon = styled(Icon)`
  margin-left: ${Padding.xxs};
`
