import {
  IConfig, ILifeCycleHook, IMonetizationItem, IState, ISubscriptionState,
} from './types'
import { MonetizationKey } from '../../../../models/enums/DefaultFirebaseRCKeys/keys/MonetizationKey'
import { LifeCycleHook } from '../../../../models/enums/OtherConfigsKeys/keys/LifeCycleHook'
import { LifeCycleKeys, MonetizationKeys } from '../../../../models/enums/OtherConfigsKeys'
import { SubscriptionState } from '../../../../models/enums/OtherConfigsKeys/keys/SubscriptionState'

export const getConfigByKey = (
  state: IState,
  key: MonetizationKey | string,
): IMonetizationItem | undefined => state.configs
  .find((config: IConfig) => config.key === key)?.data

export const getHookConfigByKey = (
  state: IState,
  key: LifeCycleHook | string,
  monetizationKey: MonetizationKey | string,
): ILifeCycleHook | undefined => {
  const monetization: IMonetizationItem | undefined = getConfigByKey(state, monetizationKey)
  return monetization?.[MonetizationKeys.LIFE_CYCLE_KEY]?.[key]
}

export const getSubscriptionConfigByKey = (
  state: IState,
  key: SubscriptionState,
  hook: LifeCycleHook | string,
  monetizationKey: MonetizationKey | string,
): ISubscriptionState | undefined => {
  const hookConfig: ILifeCycleHook | undefined = getHookConfigByKey(state, hook, monetizationKey)
  return hookConfig?.[LifeCycleKeys.SUBSCRIPTION_CONFIGS]?.[key]
}

// Meta: getConfigIndexByKey

export const getConfigIndexByKey = (
  state: IState,
  key: MonetizationKey | string,
): number => state.configs
  .findIndex((config: IConfig) => config.key === key)

// Meta: getCurrentConfig

export const getCurrentConfig = (
  state: IState,
): IConfig | undefined => state.configs
  .find(
    (config: IConfig) => config.key === state.currentConfigKeys.monetization,
  )

export const getCurrentConfigData = (
  state: IState,
): IMonetizationItem | undefined => state.configs
  .find(
    (config: IConfig) => config.key === state.currentConfigKeys.monetization,
  )?.data

export const getCurrentHookConfig = (
  state: IState,
): ILifeCycleHook | undefined => {
  const monetization: IMonetizationItem | undefined = getCurrentConfigData(state)
  return monetization?.[MonetizationKeys.LIFE_CYCLE_KEY]?.[state.currentConfigKeys.hook]
}

export const getCurrentSubscriptionConfig = (
  state: IState,
): ISubscriptionState | undefined => {
  const hookConfig: ILifeCycleHook | undefined = getCurrentHookConfig(state)
  return hookConfig?.[LifeCycleKeys.SUBSCRIPTION_CONFIGS]?.[state.currentConfigKeys.subscriptionState]
}

// Meta: getCurrentConfigKey

export const getCurrentConfigKey = (
  state: IState,
): MonetizationKey | string => state.currentConfigKeys.monetization

export const getCurrentHookKey = (
  state: IState,
): LifeCycleHook | string => state.currentConfigKeys.hook

export const getCurrentSubscriptionKey = (
  state: IState,
): SubscriptionState => state.currentConfigKeys.subscriptionState

// Meta: getConfigs

export const getConfigs = (
  state: IState,
): Array<IConfig> => state.configs

export const getHookConfigs = (
  state: IState,
  monetizationKey: MonetizationKey | string,
) => {
  const lifeCyclesConfig = state.configs
    .find(
      (config: IConfig) => config.key === monetizationKey,
    )?.data?.[MonetizationKeys.LIFE_CYCLE_KEY]
  if (!lifeCyclesConfig) {
    return undefined
  }
  return Object.entries(lifeCyclesConfig).reduce((acc, [key, hookConfig]) => {
    acc.push({
      key,
      data: hookConfig as ILifeCycleHook,
    })
    return acc
  }, [] as Array<{ key: string, data: ILifeCycleHook }>)
}

export const getSubscriptionStatesConfigs = (
  state: IState,
  hook: LifeCycleHook | string,
  monetizationKey: MonetizationKey | string,
) => {
  const subscriptionStatesConfig = getHookConfigByKey(
    state,
    hook,
    monetizationKey,
  )?.[LifeCycleKeys.SUBSCRIPTION_CONFIGS]
  if (!subscriptionStatesConfig) {
    return undefined
  }
  return Object.entries(subscriptionStatesConfig).reduce((acc, [key, hookConfig]) => {
    acc.push({
      key,
      data: hookConfig as ISubscriptionState,
    })
    return acc
  }, [] as Array<{ key: string, data: ISubscriptionState }>)
}
