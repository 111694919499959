import { ElementType } from '../enums/StyleConfigAttributesTypes/ElementType'

export const PaywallElement = {
  [ElementType.theme]: (await import('./fields/Theme')).default,
  [ElementType.screen]: (await import('./fields/Screen')).default,
  [ElementType.button]: (await import('./fields/Button')).default,
  [ElementType.image]: (await import('./fields/Image')).default,
  [ElementType.label]: (await import('./fields/Label')).default,
  [ElementType.features]: (await import('./fields/Features')).default,
  [ElementType.feature]: (await import('./fields/FeatureItem')).default,
  [ElementType.iapBlock]: (await import('./fields/IAPBlock')).default,
  [ElementType.shadow]: (await import('./fields/Shadow')).default,
}
