import styled from '@emotion/styled'
import { Color, Padding, Shadow } from '../../../theme'

export const StyledSection = styled.section`
  background: ${Color.opposite};
  height: 100%;
  box-shadow: ${Shadow.card.lg};
  position: relative;
`

export const StyledViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 54px;
`

export const StyledView = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 ${Padding.md};
  position: relative;
`
