import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)`
  margin: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  overflow-x: hidden;
  overflow-y: auto;
`
