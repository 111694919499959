import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Color, Padding, Shadow } from '../../../theme'
import AccordionItem from '../../../components/AccordionItem'
import ConfigNavigation from './_components/ConfigNavigation'
import { IStyledProps } from '../../../models/types/helper/styledComponents'
import { IHighlightTransition } from './types'

export const StyledSection = styled.section`
  background: ${Color.opposite};
  height: 100%;
  width: 100%;
  box-shadow: ${Shadow.card.lg};
  padding: ${Padding.md};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const StyledConfigElementsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: -${Padding.md};
  padding: ${Padding.md};
  overflow-x: hidden;
  overflow-y: auto;
`

export const StyledAccordionItemContentWrapper = styled(Box)`
  margin: -${Padding.sm} -${Padding.sm} -${Padding.md} -${Padding.sm};
`

export const StyledAccordionItem = styled(AccordionItem)`
  box-shadow: ${Shadow.card.sm};
  &:not(:last-child) {
    margin-bottom: ${Padding.xs};
  }
`

export const StyledElementConfigurationPlateWrapper = styled(Box)`
  padding: ${Padding.sm} ${Padding.sm} ${Padding.md} ${Padding.sm};
`

export const StyledConfigNavigation = styled(ConfigNavigation)`
  width: calc(100% + ${Padding.md} * 2);
  margin: -${Padding.md} -${Padding.md} ${Padding.md} -${Padding.md};
  box-shadow: ${Shadow.cardOpposite.lg};
`

export const StyledHighlightTransition = styled(Box)<IStyledProps<IHighlightTransition>>`
  animation-name: ${({ theme }) => (theme.toggle ? 'transition' : 'none')};
  animation-duration: 1s;

  @keyframes transition {
    from {
      background-color: ${Color.first};
    }
    20% {
      background-color: ${Color.first};
    }
    to {
      background-color: ${Color.opposite};
    }
  }
`
